import React, {useRef, useState, useEffect} from "react";
import "./Input.scss";

const Input = React.forwardRef(
  ({icon, label, error, value, onChange: changeHandler, className}, ref) => {
    const inputRef = useRef();
    const inputWrapperRef = useRef();
    const [isEdit, setIsEdit] = useState(false);

    const editHandler = () => {
      setIsEdit(true);
    };

    const enterPressHandler = (e) => {
      if (e.key === "Enter") setIsEdit(false);
    };

    useEffect(() => {
      isEdit && inputRef.current.focus();
    }, [isEdit]);

    return (
      <div ref={ref} id="base-input-component" className={className}>
        <label className="input-component__label">{label}</label>
        <div style={!isEdit ? {paddingRight: "25px"} : null} ref={inputWrapperRef}>
          <input
            disabled={!isEdit}
            ref={inputRef}
            required
            className="p-2 header-bold-2 input-component__input"
            onChange={changeHandler}
            onBlur={() => setIsEdit(false)}
            onKeyPress={enterPressHandler}
            value={value}
          />
          {!isEdit && (
            <div
              onDoubleClick={() => setIsEdit(true)}
              className="input-component__blocker"
            />
          )}
          {!isEdit && (
            <div onClick={editHandler} id="icon-field">
              {icon}
            </div>
          )}
        </div>
        {error && <div style={{color: "red"}}>{error}</div>}
      </div>
    );
  }
);

export default Input;
