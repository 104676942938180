import React, {useState} from "react";
import {Button, Input} from "reactstrap";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import DefaultLayout from "../../../components/DefaultLayout/DefaultLayout";
import {ReactComponent as DocManIcon} from "../../../assets/SupportIllustrations/docman.svg";
import CustomPaypalButton from "../../../components/UserInfo/SupportModal/CustomPaypalButton";
import {useGlobalTheming} from "../../../hooks/useGlobalTheming";
import "./SupportMobileVersion.scss";

const SupportMobileVersion = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [paid, setPaid] = useState(false);
  const [donation, setDonation] = useState(5);
  useGlobalTheming();

  const returnBack = () => {
    history.goBack();
  };

  return (
    <DefaultLayout isNonDesktop backArrow linkTitle={t("header.support")}>
      <div className="support-mobile-version">
        <div className="support-mobile-version__description">
          <DocManIcon className="support-mobile-version__description-logo" />
          <div>{paid ? t("support.success") : t("support.description-part-1")}</div>
          <div>{paid ? null : t("support.description-part-2")}</div>
          {!paid ? (
            <div className="support-mobile-version__donation_field">
              <span className="support-mobile-version__tip">
                {t("support.summ-description")}
              </span>
              <span className="support-mobile-version__input-symbol">
                <Input
                  min={1}
                  value={donation}
                  onChange={(e) => {
                    e.target.value === "" ? setDonation(1) : setDonation(e.target.value);
                  }}
                  type={"number"}
                  onFocus={(e) => e.target.scrollIntoView()}
                />
              </span>
            </div>
          ) : null}
        </div>
        <div className={"support-mobile-version__buttons-container"}>
          {paid ? (
            <Button className={"support-mobile-version__ok-button"} onClick={returnBack}>
              Ok
            </Button>
          ) : (
            <>
              <Button
                onClick={returnBack}
                className={"support-mobile-version__cancel-button"}
              >
                {t("modal.cancel")}
              </Button>
              <PayPalScriptProvider
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                  currency: "USD",
                }}
              >
                <CustomPaypalButton
                  className="support-mobile-version__paypal-button"
                  setPaid={setPaid}
                  donationValue={donation}
                />
              </PayPalScriptProvider>
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SupportMobileVersion;
