export default function findPageToRequest(list, currentRequestPage) {
  let requestPage = currentRequestPage;
  const listByPage = list.filter((item) => item.page === currentRequestPage);

  if (!listByPage.length) {
    return requestPage;
  }
  for (let item of listByPage) {
    if (!item.checked) return requestPage;
  }
  if (requestPage === 0) return requestPage;

  requestPage = requestPage - 1;
  return findPageToRequest(list, requestPage);
}
