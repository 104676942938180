import {useEffect} from "react";
import {isMobile, isTablet} from "react-device-detect";
import themeTool from "../utils/themeTool";

export const useGlobalTheming = () => {
  const isNonDesktop = isMobile || isTablet;
  useEffect(() => {
    if (isNonDesktop) {
      themeTool("[class=form-control]", "form-control-non-desktop");
      themeTool("[class=btn]", "btn-non-desktop");
    } else {
      themeTool("[class=wizard]", "min-width-app");
    }
  });
};
