import React from "react";
import {Modal, ModalBody} from "reactstrap";
import "./LegalModal.scss";
import {useTranslation} from "react-i18next";

const LegalModal = ({toggle, modal}) => {
  const {t} = useTranslation();

  return (
    <Modal centered isOpen={modal} toggle={toggle} className={"legal-modal"}>
      <ModalBody className={"legal-modal__body"}>
        <a
          onClick={toggle}
          target="_blank"
          rel="noopener noreferrer"
          href="https://drive.google.com/file/d/1kLmUEHc1TuGs8G9VWxfChSJMIeToFexR/view?usp=sharing"
        >
          {t("legal-modal.privacy-policy")}
        </a>
      </ModalBody>
    </Modal>
  );
};

export default LegalModal;
