import React, {useState} from "react";
import {CloseIcon} from "../Icons";
import {Row, Col} from "reactstrap";
import Dropzone from "react-dropzone";
import FileAnchor from "../FIleAnchor/FileAnchor";
import CreateNewTplButton from "../CreateNewTplButton/CreateNewTplButton";
import RefWrapper from "../RefWrapper";
import {Spinner} from "reactstrap";
import "./CreateNewInstanceField.scss";
import {MainColors} from "../../constants/CommonProperties";
import {useTranslation} from "react-i18next";
import {checkFile} from "../../utils";

const CreateNewTemplateField = React.forwardRef(
  (
    {
      onCreateNewDocument: createNewDocumentHandler,
      onProcessUpload: processUpload,
      onSetFile: setFile,
      onClearFile: clearFile,
      file,
      notemplates,
      isChooseRecognitionButtonActive,
      isRecognitionByTemplate,
      isNonDesktop,
      hiddenButton,
      isNewDocumentUploading,
      isPopUp,
      isStart,
      icon,
      headerText,
      buttonText,
      paragraphText,
      beforeButtonText,
      onClosePopUp: closePopUp,
    },
    ref
  ) => {
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isDragStart, setIsDragStart] = useState(false);

    const {t} = useTranslation();

    const anchorFileHandler = (files) => {
      setFile(files[0]);
    };

    const dragStartHandler = () => {
      setIsDragStart(true);
    };

    const dragStopHandler = () => {
      setIsDragStart(false);
    };

    const isWizardActive = localStorage.getItem("parsio.skipWizardStatus");

    return (
      <>
        <Row
          noGutters
          className={
            (isNonDesktop ? "new-instance-row-non-desktop " : "new-instance-row ") +
            (isPopUp
              ? `${
                  isNonDesktop ? "new-instance-row-non-desktop" : "new-instance-row"
                }__pop-up`
              : "")
          }
        >
          <Dropzone
            onDragEnter={dragStartHandler}
            onDropAccepted={dragStopHandler}
            onDragLeave={dragStopHandler}
            onDrop={async (acceptedFiles) => {
              try {
                await checkFile(acceptedFiles[0]);
                anchorFileHandler(acceptedFiles);
              } catch (err) {
                alert(err);
              }
            }}
          >
            {({getRootProps, getInputProps}) => (
              <div
                {...getRootProps({onClick: (event) => event.stopPropagation()})}
                className={"dropzone " + (isDragStart ? "dropzone_active" : "")}
              >
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
          <RefWrapper
            ref={
              ref?.wizardFirstScenarioFirstTargetRef ||
              ref?.wizardSecondScenarioSecondTargetRef
            }
          >
            <Col className="d-flex flex-column justify-content-center align-items-center h-100">
              {icon}
              <h2
                style={isNonDesktop ? {maxWidth: "270px"} : null}
                className={
                  "mt-4 black-text bold-text text-center " +
                  (isStart ? "header-bold-5" : "header-bold-4")
                }
              >
                {headerText}
              </h2>
              {paragraphText && (
                <p
                  style={isNonDesktop ? {maxWidth: "230px"} : null}
                  className="mt-1 header-regular-2 black-text text-center"
                >
                  {paragraphText}
                </p>
              )}
              <p
                style={isNonDesktop ? {maxWidth: "230px"} : null}
                className="mt-1 header-regular-2 grey-text text-center"
              >
                {beforeButtonText}
              </p>
              <CreateNewTplButton
                hidden={(file && isNonDesktop) || hiddenButton}
                ref={
                  ref?.wizardFirstScenarioFirstPointerRef ||
                  ref?.wizardSecondScenarioSecondPointerRef
                }
                className={
                  isNonDesktop ? "new-instance-row-non-desktop__choose-file-button" : ""
                }
                buttonText={buttonText}
                onAnchorFile={anchorFileHandler}
                onFileIsLoading={setIsFileLoading}
              />
              <small
                hidden={(file && isNonDesktop) || !isNonDesktop}
                className="new-instance-row-non-desktop__warning"
              >
                {t("create-new-instance-field.non-desktop-warning")}
              </small>
              {!isFileLoading && file ? (
                <FileAnchor
                  notemplates={notemplates}
                  file={file}
                  ref={ref}
                  isChooseRecognitionButtonActive={isChooseRecognitionButtonActive}
                  isRecognitionByTemplate={isRecognitionByTemplate}
                  isNewDocumentUploading={isNewDocumentUploading}
                  onSetFile={setFile}
                  onClearFile={clearFile}
                  onCreateNewDocument={createNewDocumentHandler}
                  onProcessUpload={processUpload}
                />
              ) : (
                isFileLoading && <Spinner className="mt-2" />
              )}
            </Col>
          </RefWrapper>
          {isPopUp && isWizardActive && (
            <CloseIcon
              className="close-icon"
              width="20px"
              height="20px"
              fill={MainColors.greyPalette.grey6}
              onClick={closePopUp}
            />
          )}
        </Row>
      </>
    );
  }
);

export default CreateNewTemplateField;
