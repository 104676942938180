import React, {useState, useEffect} from "react";
import {isMobile, isTablet} from "react-device-detect";

const Processing = ({
  status,
  documentID,
  onSetFailedAIRecognitions: setFailedAIRecognitions,
  ...props
}) => {
  const [progressValue, setProgressValue] = useState(5);

  const isNonDesktop = isMobile || isTablet;

  useEffect(() => {
    if (progressValue >= 97) {
      return;
    }
    setTimeout(() => {
      setProgressValue(progressValue + 4);
    }, 200);
  });

  useEffect(() => {
    if (status === "failed") {
      setFailedAIRecognitions((failedRecognitions) => [
        ...failedRecognitions,
        documentID,
      ]);
    }
  }, [setFailedAIRecognitions, status, documentID]);

  return (
    <div className="d-flex justify-content-center align-items-center" {...props}>
      {status === "in_progress" && (
        <>
          {!isNonDesktop && <span className="mr-2">processing...</span>}
          <div
            style={{
              width: "100%",
              minWidth: "70px",
              maxWidth: "80px",
              height: "3px",
              backgroundColor: "#D8D8D8",
              marginTop: "4px",
            }}
          >
            <div
              style={{
                width: `${progressValue}%`,
                height: "3px",
                backgroundColor: "#848484",
              }}
            ></div>
          </div>
          <div className="text-center">
            <span className="ml-1" style={{fontSize: "12px"}}>
              {`${progressValue.toString().padStart(2, "0")}%`}
            </span>
          </div>
        </>
      )}
      {status === "failed" && null}
    </div>
  );
};

export default Processing;
