import React from "react";
import {Button, Form, FormGroup, Label, Input, FormFeedback, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {capitalize, capitalizeFirstLetter} from "../../../../utils";
import {ReactComponent as GoogleIcon} from "../../../../assets/Google.svg";
import {ReactComponent as DocIcon} from "../../../../assets/DocIcon.svg";

const NonDesktopSignUpComponent = ({
  formik,
  apiErrors,
  privacyUrl,
  onGoogleAuth: googleAuthHandler,
  onGoToSignIn: goToSignIn,
}) => {
  const {t} = useTranslation();

  return (
    <Row noGutters className={"sign-form-non-desktop__row"}>
      <Form className={"sign-form-non-desktop__form"} onSubmit={formik.handleSubmit}>
        <div>
          <div className="p-3 sign-form-non-desktop__header">
            <DocIcon className="sign-form-non-desktop__logo" />
            {t("sign-up.sign-up").toLocaleUpperCase()}
          </div>
          <div className="p-3 sign-form-non-desktop__body">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                invalid={
                  !!(
                    formik.touched.email &&
                    (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                  )
                }
                valid={
                  !!(formik.touched.email && (!formik.errors.email || !apiErrors.email))
                }
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                placeholder={capitalizeFirstLetter(t("sign-up.email-placeholder"))}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormFeedback>
                {t(
                  formik.touched.email &&
                    (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                )}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="password1">{capitalize(t("sign-in.password"))}</Label>
              <Input
                invalid={
                  formik.touched.password1 &&
                  (formik.errors.password1 ||
                    (apiErrors.password1 && apiErrors.password1[0]))
                }
                valid={
                  formik.touched.password1 &&
                  (!formik.errors.password1 || !apiErrors.password1)
                }
                type="password"
                name="password1"
                id="password1"
                autoComplete="off"
                placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password1}
              />
              <FormFeedback>
                {t(
                  formik.touched.password1 &&
                    (formik.errors.password1 ||
                      (apiErrors.password1 && apiErrors.password1[0]))
                )}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="password2">{capitalize(t("sign-up.confirm-password"))}</Label>
              <Input
                invalid={
                  formik.touched.password2 &&
                  (formik.errors.password2 ||
                    (apiErrors.password2 && apiErrors.password2[0]))
                }
                valid={
                  formik.touched.password2 &&
                  (!formik.errors.password2 || !apiErrors.password2)
                }
                type="password"
                name="password2"
                id="password2"
                autoComplete="off"
                placeholder={capitalizeFirstLetter(
                  t("sign-in.verify-password-placeholder")
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password2}
              />
              <FormFeedback>
                {t(
                  formik.touched.password2 &&
                    (formik.errors.password2 ||
                      (apiErrors.password2 && apiErrors.password2[0]))
                )}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="business_domain">
                {capitalizeFirstLetter(t("sign-up.business-domain"))}
              </Label>
              <Input
                type="select"
                name="business_domain"
                id="business_domain"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.business_domain}
              >
                {[
                  "fintech",
                  "healthcare",
                  "education",
                  "real-estate",
                  "social-media",
                  "hr",
                  "other",
                ].map((domain) => (
                  <option key={domain} value={domain}>
                    {capitalizeFirstLetter(t(`domain.${domain}`))}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <div className="mt-4 text-danger text-center">
              {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
            </div>
          </div>
        </div>
        <div className="sign-form-non-desktop__footer">
          <div className="d-flex justify-content-end ">
            <div style={{width: "40%"}}>
              <Button
                style={{width: "100%"}}
                size="sm"
                color="link"
                className="sign-form-non-desktop__button"
                onClick={goToSignIn}
              >
                {t("sign-in.sign-in").toLocaleUpperCase()}
              </Button>
            </div>
            <div style={{width: "60%"}} className="d-flex justify-content-end">
              <Button
                style={{width: "30%"}}
                size="sm"
                color="primary"
                className="sign-form-non-desktop__button mr-2"
                onClick={googleAuthHandler}
              >
                <GoogleIcon />
              </Button>
              <Button
                style={{width: "70%"}}
                size="sm"
                type="submit"
                color="primary"
                className={"sign-form-non-desktop__button"}
              >
                {t("sign-up.sign-up").toLocaleUpperCase()}
              </Button>
            </div>
          </div>
          <div className={"sign-form-non-desktop__privacy-text"}>
            {t("sign-up.privacy-text")}
            <a target={"_blank"} href={privacyUrl}>
              {t("sign-up.privacy-link")}
            </a>
          </div>
        </div>
      </Form>
    </Row>
  );
};

export default NonDesktopSignUpComponent;
