import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
import {Redirect} from "react-router-dom";
import TemplateEditor from "../components/TemplateEditor/TemplateEditor";
import {editTemplate} from "../redux/feature/docTemplateEditorV2/actions";
import {isMobile, isTablet} from "react-device-detect";
import {useGlobalTheming} from "../hooks/useGlobalTheming";

const New = () => {
  const dispatch = useDispatch();
  const isNonDesktop = isMobile || isTablet;
  useGlobalTheming();

  const image = useSelector(
    (state) => state.docTemplateEditorV2.currentData.document?.image
  );

  const saveDocTemplateHandler = async (arg, tplId) => {
    try {
      return await dispatch(editTemplate({...arg, tplId}));
    } catch (err) {
      return err;
    }
  };

  if (isNonDesktop) {
    return <Redirect to="/recognition/results" />;
  }

  return image ? (
    <TemplateEditor
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 67,
      }}
      onSave={saveDocTemplateHandler}
    />
  ) : (
    <Redirect to="/" />
  );
};

export default withRouter(New);
