import React, {useState} from "react";
import {Modal, ModalBody, ModalFooter, Button, Spinner} from "reactstrap";
import {WarningIcon} from "../Icons";
import {useTranslation} from "react-i18next";
import "./ConfirmModal.scss";
import {MainColors} from "../../constants/CommonProperties";

const ConfirmModal = ({
  headerText,
  paragraphText,
  saveButtonText,
  close,
  onAccept: accept,
}) => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState(false);
  //TODO: remake with props icon
  return (
    <Modal isOpen>
      <ModalBody className="position-relative p-4">
        <WarningIcon
          width="16px"
          height="16px"
          fill={MainColors.systemPalette.warning}
          style={{position: "absolute", top: 32, left: 24}}
        />
        <h5 className="mb-3 pl-4 modal__header">
          <b>{headerText}</b>
        </h5>
        <div className="modal__body">
          {isLoading ? <Spinner color="primary" /> : <p>{paragraphText}</p>}
        </div>
      </ModalBody>
      <ModalFooter className="modal__footer">
        <Button size="sm" color="secondary" onClick={() => close()} disabled={isLoading}>
          {t("modal.cancel").toLocaleUpperCase()}
        </Button>{" "}
        <Button
          size="sm"
          color="primary"
          disabled={isLoading}
          onClick={async () => {
            setLoading(true);
            try {
              await accept();
            } finally {
              setLoading(false);
            }
          }}
        >
          {saveButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
