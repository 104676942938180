import React from "react";
import {CloseIcon} from "../Icons";
import {MainColors} from "../../constants/CommonProperties";
import {
  capitalize,
  capitalizeFirstLetter,
  convertMassiveDataForCSVLink,
} from "../../utils";
import {useTranslation} from "react-i18next";
import "./DocumentsEditingPopUp.scss";
import ExportFileButton from "../Buttons/ExportFileButton/ExportFileButton";

const DocumentsEditingPopUp = ({
  isShow,
  data,
  onDeleteTemplates: deleteTemplates,
  onSetDocumentListWithCheckStatus: setDocumentsListWithCheckStatus,
}) => {
  const {t} = useTranslation();

  return (
    isShow && (
      <div className={"pop-up-container"}>
        <div className="ml-5 cursor-pointer" onClick={() => deleteTemplates()}>
          <CloseIcon
            className="mr-2 mb-1"
            width="12px"
            height="12px"
            fill={MainColors.greyPalette.grey6}
          />
          <span>{capitalize(t("pop-up.clear-all"))}</span>
        </div>
        <div className="ml-3 pr-2">
          <ExportFileButton
            label={`${capitalizeFirstLetter(t("pop-up.export-all"))}...`}
            data={convertMassiveDataForCSVLink(data || [])}
            onSetDocumentListWithCheckStatus={setDocumentsListWithCheckStatus}
          />
        </div>
      </div>
    )
  );
};

export default DocumentsEditingPopUp;
