import React from "react";
import {Toast, ToastBody, ToastHeader} from "reactstrap";

const ToastComponent = ({type}) => {
  return type === "succes" ? (
    <Toast className="fixed-toast">
      <ToastHeader icon="fail">Fail</ToastHeader>
      <ToastBody>Failed to save template.</ToastBody>
    </Toast>
  ) : (
    <Toast className="fixed-toast">
      <ToastHeader icon="success">Success</ToastHeader>
      <ToastBody>Template is saved.</ToastBody>
    </Toast>
  );
};

export default ToastComponent;
