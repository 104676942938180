import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {editTemplate, loadTemplate} from "../redux/feature/docTemplateEditorV2/actions";
import {useParams, Redirect} from "react-router-dom";
import TemplateEditor from "../components/TemplateEditor/TemplateEditor";
import {Spinner} from "reactstrap";
import {isMobile, isTablet} from "react-device-detect";
import {useGlobalTheming} from "../hooks/useGlobalTheming";

const styles = {
  spinnerWrapper: {
    position: "fixed",
    top: "58px",
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const EditPage = () => {
  const {tplId} = useParams();
  const dispatch = useDispatch();
  const isNonDesktop = isMobile || isTablet;
  useGlobalTheming();

  const document = useSelector((state) => state.docTemplateEditorV2.currentData.document);
  const isTemplateLoading = useSelector((state) => state.docTemplateEditorV2.isLoading);

  useEffect(() => {
    dispatch(loadTemplate(tplId));
  }, [tplId, dispatch]);

  const editDocTemplateHandler = async (arg) => {
    try {
      return await dispatch(editTemplate({...arg, tplId}));
    } catch (err) {
      return err;
    }
  };

  if (isNonDesktop) {
    return <Redirect to="/recognition/results" />;
  }

  return (
    <>
      {isTemplateLoading ? (
        <div
          style={styles.spinnerWrapper}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner />
        </div>
      ) : (
        document && (
          <TemplateEditor
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              bottom: 0,
              top: 67,
            }}
            onSave={editDocTemplateHandler}
          />
        )
      )}
    </>
  );
};

export default EditPage;
