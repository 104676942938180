import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {fetchTemplates} from "../redux/feature/templatesV2/actions";
import {useDispatch} from "react-redux";
import {Spinner} from "reactstrap";
import DefaultLayout from "../components/DefaultLayout/DefaultLayout";
import {useGlobalTheming} from "../hooks/useGlobalTheming";

const styles = {
  spinnerWrapper: {
    position: "fixed",
    top: "58px",
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useGlobalTheming();

  useEffect(() => {
    //if there is any templates then route to 'recognition'
    //if there is not any templates then route to 'templates' with add new template field
    dispatch(fetchTemplates()).then((data) => {
      if (data.payload.results?.length) {
        history.push("/recognition/results");
      } else {
        history.push("/templates");
      }
    });
  }, [history, dispatch]);

  return (
    <DefaultLayout>
      <div
        style={styles.spinnerWrapper}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner />
      </div>
    </DefaultLayout>
  );
};

export default Home;
