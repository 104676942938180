import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import "./WarningModal.scss";

const WarningModal = ({
  headerText,
  bodyText,
  leftButtonText,
  rightButtonText,
  close,
  onClickAccept: accept,
}) => {
  return (
    <Modal className={"warning-modal"} isOpen centered>
      <ModalHeader className={"warning-modal__header"}>{headerText}</ModalHeader>
      <ModalBody className={"warning-modal__body"}>{bodyText}</ModalBody>
      <ModalFooter style={{borderTop: "none"}}>
        <Button
          className={"text-nowrap"}
          size="sm"
          color="link"
          onClick={() => {
            close();
          }}
        >
          {leftButtonText}
        </Button>{" "}
        <Button
          className={"text-nowrap"}
          size="sm"
          color="primary"
          onClick={() => accept()}
        >
          {rightButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarningModal;
