import {createSlice} from "@reduxjs/toolkit";
import {loadTemplate, saveTemplate} from "./actions";
import {ControlPanelTools} from "../../../constants/EditorConsts";

export const docTemplateEditorV2 = createSlice({
  name: "docTemplateEditorV2",
  initialState: {
    isLoading: false,
    errorText: null,
    currTool: ControlPanelTools.SELECTING,
    selectedSectionId: null,
    areasCidCounter: 0,
    tplId: null,
    isTemplateWereEdit: false,

    highlightedAreas: {
      hovered: null,
      selected: null,
    },

    initialData: {
      document: null,
      areas: [],
    },
    currentData: {
      document: null,
      areas: [],
    },
    dataBackLog: [],
    canceledActions: [],
    isUndoRedoIsActive: false,
  },

  reducers: {
    setTemplateGroup: (state, action) => {
      state.currentData.document.group = action.payload;
      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.currentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.currentData);
      }
      //---------------------------------------------------
    },
    highlightArea: (state, action) => {
      if (action.payload.eventType === "hover") {
        state.highlightedAreas.hovered = action.payload.areaId;
      }
      if (action.payload.eventType === "click") {
        state.highlightedAreas.selected = action.payload.areaId;
      }
    },
    setIsTemplateWereEdit: (state, action) => {
      state.isTemplateWereEdit = action.payload;
    },
    clearAllHighlightedAreas: (state) => {
      state.highlightedAreas.hovered = null;
      state.highlightedAreas.selected = null;
    },

    createNewDocument: (state, action) => {
      const image = action.payload.image;
      const imageBlob = action.payload.imageBlob;

      state.tplId = null;
      //initialData
      state.initialData.document = {
        width: image.width,
        height: image.height,
        image: image,
        imageBlob: imageBlob,
        fileName: action.payload.fileName,
        templateName: "Template name #",
        group: null,
      };
      state.initialData.areas = [];
      //currentData
      state.currentData.document = {
        width: image.width,
        height: image.height,
        image: image,
        imageBlob: imageBlob,
        fileName: action.payload.fileName,
        templateName: "Template name #",
        group: null,
      };
      state.currentData.areas = [];

      //backlog
      state.dataBackLog = [];
      state.canceledActions = [];
      state.dataBackLog.push(state.currentData);
    },
    addToBacklogWithDebounce: (state) => {
      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.currentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.currentData);
      }
      //---------------------------------------------------
    },
    createNewArea: (state, action) => {
      const areaParams = action.payload;
      const cid = `area_${state.areasCidCounter}`;
      state.currentData.areas = [...state.currentData.areas, {cid, ...areaParams}];
      state.areasCidCounter = state.areasCidCounter + 1;
      state.highlightedAreas = {...state.highlightedAreas, selected: cid};

      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.currentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.currentData);
      }
      //---------------------------------------------------
    },
    editArea: (state, action) => {
      //redundant variable
      const areas = state.currentData.areas.map((area) =>
        area.cid === action.payload.area.cid ? {...area, ...action.payload.data} : area
      );
      state.currentData.areas = areas;

      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.currentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.currentData);
      }
      //---------------------------------------------------
    },
    moveArea: (state, action) => {
      //redundant variable
      const areas = state.currentData.areas.map((area) =>
        area.cid === action.payload.cid ? {...area, ...action.payload} : area
      );
      state.currentData.areas = areas;

      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.currentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.currentData);
      }
      //---------------------------------------------------
    },
    resizeArea: (state, action) => {
      //redundant variable
      const areas = state.currentData.areas.map((area) =>
        area.cid === action.payload.cid ? {...area, ...action.payload} : area
      );
      state.currentData.areas = areas;
    },
    removeArea: (state, action) => {
      //redundant variable
      const areas = state.currentData.areas.filter(
        (area) => area.cid !== action.payload.cid
      );
      state.currentData.areas = areas;

      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.currentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.currentData);
      }
      //---------------------------------------------------
    },
    setCurrTool: (state, action) => {
      state.currTool = action.payload;
    },
    setDocumentName: (state, action) => {
      state.currentData.document.templateName = action.payload;
    },
    returnOneActionBack: (state) => {
      if (state.dataBackLog.length > 1) {
        state.isUndoRedoIsActive = true;

        const canceledAction = state.dataBackLog.pop();
        state.canceledActions.push(canceledAction);

        state.currentData = state.dataBackLog[state.dataBackLog.length - 1];
      }
    },
    returnOneActionForward: (state) => {
      if (state.canceledActions.length) {
        state.isUndoRedoIsActive = true;

        const lastCanceledAction = state.canceledActions.pop();
        state.currentData = lastCanceledAction;
        state.dataBackLog.push(lastCanceledAction);
      }
    },
  },
  extraReducers: {
    [saveTemplate.fulfilled]: (state, action) => {
      state.tplId = action.payload.id;
    },
    [loadTemplate.pending]: (state) => {
      state.isLoading = true;
      state.errorText = null;
    },
    [loadTemplate.fulfilled]: (state, action) => {
      const template_region_coordinates = action.payload.template_region_coordinates.map(
        (region, index) => {
          region.cid = `area_${index}`;
          return region;
        }
      );
      state.isLoading = false;
      state.errorText = null;

      state.tplId = action.payload.id;
      //initialData
      state.initialData.document = {
        width: action.payload.img.width,
        height: action.payload.img.height,
        image: action.payload.img,
        imageBlob: action.payload.imageBlob,
        fileName: action.payload.fileName,
        templateName: action.payload.name,
        group: action.payload.group,
        lastModified: action.payload.last_modified
      };
      state.initialData.areas = template_region_coordinates;
      //currentData
      state.currentData.document = {
        width: action.payload.img.width,
        height: action.payload.img.height,
        image: action.payload.img,
        imageBlob: action.payload.imageBlob,
        fileName: action.payload.fileName,
        templateName: action.payload.name,
        group: action.payload.group,
      };
      state.currentData.areas = template_region_coordinates;

      //backlog
      state.dataBackLog = [];
      state.canceledActions = [];
      state.dataBackLog.push(state.currentData);
      //
      state.areasCidCounter = template_region_coordinates.length;
    },
    [loadTemplate.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
  },
});

export const {
  setTemplateGroup,
  highlightArea,
  clearAllHighlightedAreas,
  createNewDocument,
  createNewArea,
  editArea,
  moveArea,
  resizeArea,
  removeArea,
  setCurrTool,
  setDocumentName,
  returnOneActionBack,
  returnOneActionForward,
  setIsTemplateWereEdit,
} = docTemplateEditorV2.actions;

export default docTemplateEditorV2.reducer;
