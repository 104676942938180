import React from "react";
import {Button} from "reactstrap";
import {BackAction, ForwardAction} from "../../Icons";
import {MainColors} from "../../../constants/CommonProperties";
import "./ActionsManagePanel.scss";

const ActionsManagePanel = ({
  style,
  canceledActions,
  backlog,
  className = "",
  onReturnOneActionBack: returnOneActionBack,
  onReturnOneActionForward: returnOneActionForward,
  ...props
}) => {
  const leftButtonDisabled = backlog?.length === 1;
  const rightButtonDisabled = canceledActions?.length === 0;

  return (
    <div style={style} className={`${className} manage-actions-panel`} {...props}>
      <Button
        disabled={leftButtonDisabled}
        title={"Undo"}
        onClick={returnOneActionBack}
        className="manage-actions-panel__left-button"
        style={
          leftButtonDisabled && rightButtonDisabled
            ? {borderRight: "1px solid #dbdfe4"}
            : null
        }
      >
        <BackAction width="20px" height="20px" fill={MainColors.greyPalette.grey5} />
      </Button>
      <Button
        disabled={rightButtonDisabled}
        title={"Redo"}
        onClick={returnOneActionForward}
        className="manage-actions-panel__right-button"
      >
        <ForwardAction width="20px" height="20px" fill={MainColors.greyPalette.grey5} />
      </Button>
    </div>
  );
};

export default ActionsManagePanel;
