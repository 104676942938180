import React, {useState, useRef} from "react";
import "./TemplateItem.scss";
import {DropDownIcon} from "../../Icons";
import {ListGroup, ListGroupItem} from "reactstrap";
import {VisibilityIcon, PencilIcon, CloseIcon} from "../../Icons";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {
  capitalizeFirstLetter,
  getDateFromDateTime,
  getTimeFromDateTime,
  truncateString,
} from "../../../utils";
import ImageWithAuth from "../../ImageWithAuth/ImageWithAuth";
import {useTranslation} from "react-i18next";

const TemplateItem = ({
  template,
  onDeleteTemplate: deleteTemplateHandler,
  onPageChange: pageChangeHandler,
}) => {
  const {t} = useTranslation();

  const [showDropDown, setShowDropDown] = useState(false);
  const dropDownRef = useRef();

  useOnClickOutside(dropDownRef, () => setShowDropDown(false));

  console.log(showDropDown);

  return (
    <div
      className="template-list"
      onDoubleClick={() => pageChangeHandler(`/edit/${template.id}`)}
    >
      <div className="template-list__item">
        <ImageWithAuth url={template.template_image} />
        <div className={"item__container"}>
          <p title={template.name} className="m-0 pt-2 pr-2 pl-2">
            {truncateString(template.name, 14)}
          </p>
          <div className="item__icon-container p-2 pb-3">
            <small style={{color: "#646970"}} className="m-0">
              {`${getTimeFromDateTime(template.last_modified)} ${getDateFromDateTime(
                template.last_modified
              )}`}
            </small>
            <DropDownIcon
              onClick={() => setShowDropDown(true)}
              className="cursor-pointer"
              width="16px"
              height="16px"
              fill="#646970"
            />
          </div>
        </div>
        {showDropDown && (
          <div ref={dropDownRef}>
            <ListGroup className={"item__dropdown-container"}>
              <ListGroupItem
                className="p-2 dropdown__item"
                onClick={() => pageChangeHandler(`/edit/${template.id}`)}
              >
                <PencilIcon width="16px" height="16px" fill="#646970" />
                <span>{capitalizeFirstLetter(t("modal.edit"))}</span>
              </ListGroupItem>
              <ListGroupItem
                className="p-2 dropdown__item"
                onClick={() => pageChangeHandler("/recognition")}
              >
                <VisibilityIcon width="16px" height="16px" fill="#646970" />
                <span>{capitalizeFirstLetter(t("modal.recognize"))}</span>
              </ListGroupItem>
              <ListGroupItem
                className="p-2 dropdown__item"
                onClick={() => deleteTemplateHandler(template)}
              >
                <CloseIcon width="16px" height="16px" fill="#646970" />
                <span>{capitalizeFirstLetter(t("modal.delete"))}</span>
              </ListGroupItem>
            </ListGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateItem;
