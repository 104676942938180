import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Alert,
} from "reactstrap";
import {ReactComponent as GoogleIcon} from "../../../../assets/Google.svg";
import {useTranslation} from "react-i18next";
import {capitalize, capitalizeFirstLetter} from "../../../../utils";

const DesktopSignInComponent = ({
  alertText,
  isAlertShown,
  formik,
  apiErrors,
  privacyUrl,
  onGoogleAuth: googleAuthHandler,
  onGoToSignUp: goToSignUp,
  onGoToReset: goToReset,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <Alert isOpen={isAlertShown} className={"success-alert"} color="success">
        {alertText}
      </Alert>
      <Row noGutters className={"sign-form__row"}>
        <Form onSubmit={formik.handleSubmit} className={"sign-form__form"}>
          <div className="p-3 sign-form__header">{capitalize(t("sign-in.sign-in"))}</div>

          <div className="p-3 sign-form__body">
            <Button
              size="sm"
              color="primary"
              className="sign-form__button mb-2 mt-2"
              onClick={googleAuthHandler}
            >
              <GoogleIcon className="mr-2" />
              {t("sign-up.google-auth").toLocaleUpperCase()}
            </Button>
            <FormGroup>
              <Label for="email">{capitalizeFirstLetter(t("sign-in.email"))}</Label>
              <Input
                invalid={
                  !!(
                    formik.touched.email &&
                    (formik.errors.email ||
                      (apiErrors.email && Boolean(apiErrors.email[0])))
                  )
                }
                valid={formik.touched.email && (!formik.errors.email || !apiErrors.email)}
                type="email"
                name="email"
                id="email"
                autoComplete="off"
                placeholder={capitalizeFirstLetter(t("sign-in.email-placeholder"))}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormFeedback>
                {t(
                  formik.touched.email &&
                    (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                )}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="password">{capitalize(t("sign-in.password"))}</Label>
              <Input
                invalid={
                  !!(
                    formik.touched.password &&
                    (formik.errors.password ||
                      (apiErrors.password && Boolean(apiErrors.password[0])))
                  )
                }
                valid={
                  formik.touched.password &&
                  (!formik.errors.password || !apiErrors.password)
                }
                type="password"
                name="password"
                id="password"
                autoComplete="off"
                placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <FormFeedback>
                {t(
                  formik.touched.password &&
                    (formik.errors.password ||
                      (apiErrors.password && apiErrors.password[0]))
                )}
              </FormFeedback>
            </FormGroup>
            <div className="mt-4 text-danger text-center">
              {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  size="sm"
                  color="link"
                  className="sign-form__button"
                  onClick={goToReset}
                >
                  {t("sign-in.forgot-password").toLocaleUpperCase()}
                </Button>
              </div>
              <div>
                <Button
                  size="sm"
                  color="link"
                  className="mr-2 sign-form__button"
                  onClick={goToSignUp}
                >
                  {t("sign-in.no-account").toLocaleUpperCase()}
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  color="primary"
                  className="sign-form__button"
                >
                  {t("sign-in.sign-in").toLocaleUpperCase()}
                </Button>
              </div>
            </div>
            <div className={"sign-form-non-desktop__privacy-text"}>
              {t("sign-in.privacy-text")}
              <br />
              <a target={"_blank"} href={privacyUrl}>
                {t("sign-in.privacy-link")}
              </a>
            </div>
          </div>
        </Form>
      </Row>
    </>
  );
};

export default DesktopSignInComponent;
