import React from "react";
import {Link} from "react-router-dom";
import {Navbar, Nav, NavItem, NavLink} from "reactstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "../Header.scss";
import UserInfo from "../../UserInfo/UserInfo";
import {ReactComponent as DocIcon} from "../../../assets/DocIcon.svg";

const TemplateEditorHeader = ({onShowWarningModal}) => {
  const isLoggedIn = useSelector((state) => state.authV2.isLoggedIn);

  const {t} = useTranslation();

  const showWarningModal = (url) => {
    onShowWarningModal({whereToGoAfterAccept: url});
  };

  const changeActivePageHandler = (url) => {
    showWarningModal(url);
  };

  return (
    <>
      <Navbar
        light
        expand="md"
        className="pt-3 pb-3 justify-content-between position-relative"
      >
        <div className={"header-navbar__container"}>
          <a href={process.env.REACT_APP_LANDING_URL}>
            <DocIcon />
          </a>
          <Nav>
            <NavItem>
              <NavLink
                tag={Link}
                active
                onClick={() => changeActivePageHandler("/templates")}
              >
                {t("header.templates").toLocaleUpperCase()}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} onClick={() => changeActivePageHandler("/recognition")}>
                {t("header.recognition").toLocaleUpperCase()}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {isLoggedIn && (
          <div className={"header-navbar__container"}>
            <UserInfo onShowWarningBeforeLogout={showWarningModal} />
          </div>
        )}
      </Navbar>
    </>
  );
};

export default TemplateEditorHeader;
