import React from "react";
import {FileIcon, CloseIcon} from "../Icons";
import {MainColors} from "../../constants/CommonProperties";
import {Button} from "reactstrap";
import {truncateString} from "../../utils";
import {useTranslation} from "react-i18next";
import {Spinner} from "reactstrap";
import "./FileAnchor.scss";
import RefWrapper from "../RefWrapper";
import {ReactComponent as RecognizeIcon} from "../../assets/RecognizeIcon.svg";
import {isMobile, isTablet} from "react-device-detect";

const FileAnchor = React.forwardRef(
  (
    {
      file,
      notemplates,
      isNewDocumentUploading,
      isChooseRecognitionButtonActive,
      isRecognitionByTemplate,
      onCreateNewDocument: createNewDocumentHandler,
      onProcessUpload: processUpload,
      onSetFile: setFileHandler,
      onClearFile: clearFile,
    },
    ref
  ) => {
    const {t} = useTranslation();
    const isNonDesktop = isMobile || isTablet;

    return (
      <div
        className={
          isNonDesktop
            ? "file-anchor-non-desktop"
            : `file-anchor mt-5 p-0 ${
                isChooseRecognitionButtonActive && !isRecognitionByTemplate
                  ? "flex-column"
                  : "flex-row"
              }`
        }
      >
        {isNewDocumentUploading ? (
          <Spinner />
        ) : (
          <>
            <div
              className={
                isNonDesktop
                  ? "file-title-non-desktop__container p-1"
                  : "file-title__container p-1"
              }
            >
              <FileIcon
                width="14px"
                height="14px"
                fill={MainColors.purplePrimary.purplePrimary}
                className="mr-2"
              />
              <span className={"file-title__title"}>
                {truncateString(file.name || "", 20)}
              </span>
              <CloseIcon
                width="9px"
                height="9px"
                fill={MainColors.greyPalette.grey5}
                className="ml-2 cursor-pointer file-title__close-icon"
                style={{zIndex: 2}}
                onClick={() => clearFile()}
              />
            </div>
            {isChooseRecognitionButtonActive && !isRecognitionByTemplate ? (
              <div
                className={
                  isNonDesktop
                    ? "file-anchor-non-desktop__buttons-wrapper mt-3"
                    : "d-flex mt-3"
                }
              >
                <RefWrapper
                  className={`recognize-usin-template-button-wrapper ${
                    isNonDesktop || `mr-2`
                  }`}
                  ref={
                    ref?.wizardFirstScenarioSecondTargetRef ||
                    ref?.wizardSecondScenarioThirdTargetRef
                  }
                >
                  <Button
                    onClick={
                      notemplates
                        ? () => createNewDocumentHandler()
                        : () => processUpload("recognize using template")
                    }
                    size="sm"
                    color="primary"
                    className={isNonDesktop ? "w-100" : ""}
                  >
                    <RecognizeIcon />
                    {notemplates
                      ? t("modal.create-template").toLocaleUpperCase()
                      : t(
                          "file-anchor.recognize-using-template-button"
                        ).toLocaleUpperCase()}
                  </Button>
                </RefWrapper>
                <Button
                  onClick={() => processUpload("recognize using ai")}
                  size="sm"
                  color="primary"
                  style={{zIndex: 2}}
                  className={isNonDesktop ? "w-100" : ""}
                >
                  <RecognizeIcon />
                  {t("file-anchor.recognize-using-ai-button").toLocaleUpperCase()}
                </Button>
              </div>
            ) : (
              <RefWrapper
                className={`done-button-wrapper mr-2`}
                ref={
                  ref?.wizardFirstScenarioSecondTargetRef ||
                  ref?.wizardSecondScenarioThirdPointerRef
                }
              >
                <Button
                  onClick={
                    isRecognitionByTemplate
                      ? () => createNewDocumentHandler("recognize using template")
                      : createNewDocumentHandler
                  }
                  size="sm"
                  color="primary"
                >
                  {t("file-anchor.done-button").toLocaleUpperCase()}
                </Button>
              </RefWrapper>
            )}
          </>
        )}
      </div>
    );
  }
);

export default FileAnchor;
