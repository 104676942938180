import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchWithToken} from "../../../utils";
import {getImageWithAuth} from "../../../utils";

const tokenFetch = fetchWithToken(fetch);

//function for conversion areas to necessary format => it's need before send to backend
export function areasToJson(areas) {
  const transformedAreas = areas.map((area) => {
    const transformedArea = {};
    transformedArea.name = area.name;
    transformedArea.lang = area.lang;

    transformedArea.x = area.x;
    transformedArea.y = area.y;

    transformedArea.width = area.width;
    transformedArea.height = area.height;

    return transformedArea;
  });

  return JSON.stringify(transformedAreas);
}

export const loadTemplate = createAsyncThunk(
  "templateEditor/fetchTemplate",
  async (arg, thunkAPI) => {
    try {
      const response = await tokenFetch(`/api/v1/templates/${arg}/`);

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      const img = new Image();
      let imageBlob;
      let fileName = data.template_image.slice(
        data.template_image.lastIndexOf("/") + 1,
        data.template_image.length
      );

      getImageWithAuth(data.template_image).then((url) => (img.src = url));

      await new Promise((resolve, reject) => {
        img.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;

          let context = canvas.getContext("2d");
          context.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            imageBlob = blob;
            resolve();
          });
        };
        img.onerror = () => {
          reject();
        };
      });

      return {...data, tplID: arg, img, imageBlob, fileName};
    } catch (error) {
      throw error;
    }
  }
);

export const saveTemplate = createAsyncThunk(
  "templateEditor/saveTemplate",
  async (arg, thunkAPI) => {
    const formData = new FormData();

    formData.append("name", arg.document.templateName.trim());
    formData.append("template_region_coordinates", areasToJson(arg.areas));
    formData.append("template_image", arg.document.imageBlob, arg.document.fileName);
    formData.append("group", JSON.stringify(arg.document.group));
    formData.append("last_modified", new Date().toISOString());

    try {
      const response = await tokenFetch(`/api/v1/templates/`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const editTemplate = createAsyncThunk("templateEditor", async (arg, thunkAPI) => {
  const formData = new FormData();
  formData.append("name", arg.document.templateName.trim());
  formData.append("template_region_coordinates", areasToJson(arg.areas));
  formData.append("group", JSON.stringify(arg.document.group));
  if (arg.meta && arg.meta === "cancel") {
    formData.append("last_modified", arg.document.lastModified);
  } else {
    formData.append("last_modified", new Date().toISOString());
  }

  try {
    const response = await tokenFetch(`/api/v1/templates/${arg.tplId}/`, {
      method: "PATCH",
      body: formData,
    });

    const data = await response.json();

    if (response.status === 401) {
      localStorage.removeItem("token");
    }

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return data;
  } catch (error) {
    throw error;
  }
});

export const deleteTemplate = createAsyncThunk("templateEditor", async (arg) => {
  try {
    const response = await tokenFetch(`/api/v1/templates/${arg}/`, {
      method: "DELETE",
    });

    if (response.status === 401) {
      localStorage.removeItem("token");
    }

    if (!response.ok) {
      return Promise.reject("Something is going wrong. Try later");
    }

    return null;
  } catch (error) {
    throw error;
  }
});
