import React, {useEffect} from "react";
import {CloseIcon} from "../../../components/Icons";
import {MainColors} from "../../../constants/CommonProperties";
import {Table} from "reactstrap";
import DocumentsEditingPopUp from "../../../components/DocumentsEditingPopUp/DocumentsEditingPopUp";
import "./RecognitionResultList.scss";
import {
  getTimeFromDateTime,
  getDateFromDateTime,
  capitalizeFirstLetter,
} from "../../../utils";
import {convertDataForCSVLink} from "../../../utils";
import {useTranslation} from "react-i18next";
import ExportFileButton from "../../../components/Buttons/ExportFileButton/ExportFileButton";
import Processing from "../RecognitionResultList/Processing";
import usePrevious from "../../../hooks/usePrevious";

const RecognitionResultList = React.forwardRef(
  (
    {
      documents,
      documentsListWithCheckStatus,
      documentRequestPage,
      isDocumentsWasDeletedByPopUp,
      onSetDocumentListWithCheckStatus: setDocumentsListWithCheckStatus,
      onDeleteDocument: deleteDocument,
      onDeleteDocumentsByEditingPopUp: deleteDocumentByEditingPopUp,
      onShowDocument: showDocument,
      onSetFailedAIRecognitions: setFailedAIRecognitions,
      onSetIsDocumentsWasDeletedByPopUp: setIsDocumentsWasDeletedByPopUp,
    },
    ref
  ) => {
    const {t} = useTranslation();

    const checkAllCheckBoxIsTrue = () => {
      for (let item of documentsListWithCheckStatus) {
        if (item.page === documentRequestPage) {
          if (!item.checked) return false;
        }
      }
      return true;
    };

    const checkIsPopUpCheckBoxChecked = () => {
      for (let item of documentsListWithCheckStatus) {
        if (item.page === documentRequestPage) {
          if (item.checked) return true;
        }
      }
      return false;
    };

    const checkIsIDCheckboxChecked = (id) => {
      for (let item of documentsListWithCheckStatus) {
        if (item.page === documentRequestPage) {
          if (item.checked && item.id === id) return true;
        }
      }
      return false;
    };

    const checkBoxChangeHandler = (e, document) => {
      e.persist();

      setDocumentsListWithCheckStatus((documentsListWithCheck) =>
        documentsListWithCheck.map((item) => {
          if (item.id === document.id) item.checked = !item.checked;
          return item;
        })
      );
    };

    const toogleAllCheckBoxHandler = () => {
      setDocumentsListWithCheckStatus((documentsListWithCheck) => {
        let updated = [];
        const isSomeCheckBoxActive = documentsListWithCheck.find(
          (document) => document.checked && document.page === documentRequestPage
        );

        if (isSomeCheckBoxActive) {
          updated = documentsListWithCheck.map((item) => {
            if (item.page === documentRequestPage) {
              item.checked = false;
            }
            return item;
          });
        } else {
          updated = documentsListWithCheck.map((item) => {
            if (item.page === documentRequestPage) {
              item.checked = true;
            }
            return item;
          });
        }

        return updated;
      });
    };

    const generateHeaders = () => [
      "",
      "list-view.file-name",
      "list-view.time",
      "list-view.date",
      "list-view.template",
      "list-view.group",
      "list-view.actions",
    ];

    const tableClassNamePointersList = [
      "checkbox-field",
      "file-name",
      "time",
      "date",
      "template",
      "group",
      "actions",
    ];

    const prevDocumentRequestPage = usePrevious(documentRequestPage);
    useEffect(() => {
      if (
        documents.length &&
        (documentRequestPage !== prevDocumentRequestPage || isDocumentsWasDeletedByPopUp)
      ) {
        setIsDocumentsWasDeletedByPopUp(false);
        setDocumentsListWithCheckStatus((documentsListWithCheckStatus) => {
          documents.forEach((newDocument) => {
            const existedDocument = documentsListWithCheckStatus.find(
              (document) => document.id === newDocument.id
            );
            if (existedDocument) {
              return;
            } else {
              documentsListWithCheckStatus.push({
                ...newDocument,
                checked: false,
                page: documentRequestPage,
              });
            }
          });

          return documentsListWithCheckStatus;
        });
      }
    }, [
      documents,
      setDocumentsListWithCheckStatus,
      setIsDocumentsWasDeletedByPopUp,
      documentRequestPage,
      isDocumentsWasDeletedByPopUp,
      prevDocumentRequestPage,
    ]);

    useEffect(() => {
      let actionsColumn = document.querySelector(".documents-table__actions");

      actionsColumn.style.width = documents.find(
        (document) => document.status === "in_progress"
      )
        ? "25%"
        : "10%";
    });

    return (
      <div className="recognition-result-list">
        <DocumentsEditingPopUp
          isShow={checkIsPopUpCheckBoxChecked()}
          data={documentsListWithCheckStatus.filter((item) => item.checked)}
          onDeleteTemplates={deleteDocumentByEditingPopUp}
          onSetDocumentListWithCheckStatus={setDocumentsListWithCheckStatus}
        />
        <Table striped borderless className="documents-table position-relative">
          <thead>
            <tr className="position-relative">
              {generateHeaders().map((header, index) => {
                if (index === 0)
                  return (
                    <th
                      key={header}
                      className={`documents-table__${tableClassNamePointersList[0]}`}
                    >
                      <label
                        className={
                          checkAllCheckBoxIsTrue()
                            ? "checkbox-container main active"
                            : "checkbox-container main intermediate"
                        }
                        style={{bottom: "12px"}}
                      >
                        <input
                          checked={checkIsPopUpCheckBoxChecked()}
                          onClick={() => toogleAllCheckBoxHandler()}
                          readOnly
                          type="checkbox"
                        />
                        <span className="checkmark" style={{zIndex: 2}} />
                      </label>
                    </th>
                  );
                return (
                  <th
                    className={`metadata-bold-2 documents-table__${tableClassNamePointersList[index]}`}
                    key={header}
                  >
                    {capitalizeFirstLetter(t(header))}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => (
              <tr
                key={document.id}
                style={
                  checkIsIDCheckboxChecked(document.id)
                    ? {backgroundColor: MainColors.purplePrimary.purpleClick}
                    : null
                }
                className={"cursor-pointer"}
              >
                <td>
                  <label className="checkbox-container">
                    <input
                      onChange={(e) => checkBoxChangeHandler(e, document)}
                      type="checkbox"
                      checked={checkIsIDCheckboxChecked(document.id)}
                    />
                    <span className="checkmark" />
                  </label>
                </td>
                <td onClick={() => showDocument(document)}>
                  <span
                    title={decodeURI(document.filename)}
                    className="body-regular"
                    style={{color: MainColors.greyPalette.typeBlack}}
                  >
                    {decodeURI(document.filename) || ""}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span className="body-regular">
                    {getTimeFromDateTime(document.datetime)}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span className="body-regular">
                    {getDateFromDateTime(document.datetime)}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span className="body-regular">{document.template?.name || ""}</span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span id={"group"}>
                    {document.template?.group?.name.toUpperCase() ||
                      t("dropdown.no-group").toLocaleUpperCase()}
                  </span>
                </td>
                <td className='overflow_unset'>
                  {document.status === "success" ? (
                    // TODO: Использование id здесь не правильно, т.к. у таблицы несколько строк и каждого этот id повторяется. Надо переделать или на классы. Требуется перемсмотреть целесообразность ипспользования id здесь.
                    <div id="actions_icons">
                      <ExportFileButton
                        data={convertDataForCSVLink(document?.texts || [])}
                      />
                      <CloseIcon
                        onClick={() => deleteDocument(document)}
                        width="11px"
                        height="11px"
                        fill={MainColors.greyPalette.grey5}
                      />
                    </div>
                  ) : (
                    <Processing
                      status={document.status}
                      documentID={document.id}
                      onSetFailedAIRecognitions={setFailedAIRecognitions}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
);

export default RecognitionResultList;
