import React, {useState, useRef} from "react";
import {ListGroup, ListGroupItem} from "reactstrap";
import {MainColors} from "../../constants/CommonProperties";
import "./CustomSearchDropDown.scss";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {SearchIcon} from "../Icons";
import {useTranslation} from "react-i18next";
import {capitalize} from "../../utils";
import Highlighter from "react-highlight-words";

const CustomSearchDropDown = ({
  component,
  style,
  data,
  onSetActive: setActive,
  onChoose: setValue,
  onSearchTemplates: searchTemplates,
}) => {
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dropDownRef = useRef();
  const {t} = useTranslation();

  useOnClickOutside(dropDownRef, () => {
    setIsShowDropDown(false);
    setActive(false);
    setSearchValue("");
  });

  const chooseItemHandler = (...args) => {
    setIsShowDropDown(false);
    setValue(...args);
    setActive(false);
  };

  const searchInputChangeHandler = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    searchTemplates({limit: 1000, query: value});
  };

  const clickHandler = () => {
    setIsShowDropDown(true);
    setActive(true);
  };

  const customFindChunksFunction = ({searchWords, textToHighlight}) => {
    const textLow = textToHighlight.toLowerCase();
    const searchWord = searchWords[0].toLowerCase();

    if (textLow.indexOf(searchWord) === -1) {
      return [];
    }
    return [
      {
        start: textLow.indexOf(searchWord),
        end: textLow.indexOf(searchWord) + searchWord.length,
      },
    ];
  };

  return (
    <div style={style}>
      <div onClick={() => clickHandler()} className="position-relative">
        {component}
      </div>
      {isShowDropDown && (
        <div ref={dropDownRef}>
          <ListGroup className={"list-group"}>
            <ListGroupItem
              style={{borderBottom: "1px solid #E6E8ED"}}
              className="pb-2 pr-2 pl-2 pt-2 list-group__list-item"
            >
              <input
                className={"list-item__input"}
                type="text"
                placeholder={capitalize(t("dropdown.search"))}
                value={searchValue}
                onChange={searchInputChangeHandler}
              />
              {!searchValue && (
                <SearchIcon
                  className={"list-item__search-icon"}
                  width="16px"
                  height="16px"
                  fill={MainColors.greyPalette.grey5}
                />
              )}
            </ListGroupItem>
            {data.length ? (
              data.map((item) => (
                <ListGroupItem
                  key={item.id}
                  onClick={() => chooseItemHandler(item.id)}
                  className="p-2 border-0 list-item__dropdown-item"
                >
                  <Highlighter
                    highlightClassName="list-item__dropdown-item_highlighted"
                    findChunks={customFindChunksFunction}
                    searchWords={[searchValue]}
                    textToHighlight={item.name}
                  />
                </ListGroupItem>
              ))
            ) : (
              <div className="pb-3 pt-4 pr-4 pl-4 list-item__no-data-placeholder">
                {t("dropdown.no-templates")}
              </div>
            )}
            <ListGroupItem
              onClick={() => chooseItemHandler(null, "using AI")}
              className="p-2 border-0 list-item__dropdown-item"
            >
              {t("recognize.ai-recognition")}
            </ListGroupItem>
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default CustomSearchDropDown;
