import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import {RecoverScheme} from "../../formik/validate";
import {passwordResetRequest} from "../../store/actions/auth";
import {Redirect, useHistory} from "react-router-dom";
import {ReactComponent as DocIcon} from "../../assets/DocIcon.svg";
import {isMobile, isTablet} from "react-device-detect";
import {useGlobalTheming} from "../../hooks/useGlobalTheming";

const PasswordRecover = () => {
  const [isLoading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isCodeSent, setCodeSent] = useState(false);
  const {t} = useTranslation();
  const history = useHistory();
  useGlobalTheming();
  const isNonDesktop = isMobile || isTablet;

  //Little workaround, checking if route rendered from link or from direct url
  const isFromLink = history.action === "PUSH";

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RecoverScheme,
  });

  const isSaveButtonDisabled = formik.values.email === "" || !!formik.errors.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCodeSent(false);
    setApiErrors({});
    setLoading(true);
    try {
      await passwordResetRequest(formik.values.email);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setApiErrors({non_field_errors: err});
    } finally {
      setCodeSent(true);
    }
  };

  return isFromLink ? (
    <Row
      noGutters
      className={isNonDesktop ? "sign-form-non-desktop__row" : "sign-form__row"}
    >
      <Form
        onSubmit={handleSubmit}
        className={isNonDesktop ? "sign-form-non-desktop__form" : "sign-form__form"}
      >
        <div>
          <div
            className={`p-3 ${
              isNonDesktop ? "sign-form-non-desktop__header" : "sign-form__header"
            }`}
          >
            {isNonDesktop && <DocIcon className="sign-form-non-desktop__logo" />}
            {isNonDesktop
              ? t("reset-password.reset-password").toLocaleUpperCase()
              : capitalize(t("reset-password.reset-password"))}
          </div>
          <div
            className={
              isNonDesktop ? "sign-form-non-desktop__body p-3" : "sign-form__body p-3"
            }
          >
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                invalid={
                  !!(
                    formik.touched.email &&
                    (formik.errors.email ||
                      (apiErrors.email && Boolean(apiErrors.email[0])))
                  )
                }
                valid={formik.touched.email && (!formik.errors.email || !apiErrors.email)}
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                placeholder={capitalizeFirstLetter(t("sign-up.email-placeholder"))}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormFeedback>
                {t(
                  formik.touched.email &&
                    (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                )}
              </FormFeedback>
            </FormGroup>
            {isLoading && (
              <div className={"d-flex justify-content-center"}>
                <Spinner color="primary" />
              </div>
            )}
            {apiErrors.non_field_errors && apiErrors.non_field_errors[0] && (
              <div className="mt-4 text-danger text-center">
                {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
              </div>
            )}
            {isCodeSent && (
              <div className="mt-4 mb-4 text-success text-center">
                {capitalizeFirstLetter(t("reset-password.code-sent"))}
              </div>
            )}
          </div>
        </div>
        <div
          className={`d-flex justify-content-end ${
            isNonDesktop ? "sign-form-non-desktop__footer" : "sign-form__footer"
          }`}
        >
          <Button
            style={isNonDesktop ? {width: "50%"} : null}
            size="sm"
            color="link"
            className={
              isNonDesktop
                ? "sign-form-non-desktop__button mr-3"
                : "sign-form__button mr-3"
            }
            onClick={() => history.push("/signin")}
          >
            {t("modal.cancel").toLocaleUpperCase()}
          </Button>
          <Button
            style={isNonDesktop ? {width: "50%"} : null}
            color="primary"
            className={
              isNonDesktop ? "sign-form-non-desktop__button" : "sign-form__button"
            }
            disabled={isSaveButtonDisabled}
          >
            {t("reset-password.reset").toLocaleUpperCase()}
          </Button>
        </div>
      </Form>
    </Row>
  ) : (
    <Redirect to={"/signin"} />
  );
};

export default PasswordRecover;
