import React, {useState, useRef, useEffect} from "react";
import TemplateItem from "./TemplateItem/TemplateItem";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import ListView from "../ListView/ListView";
import ImageWithAuth from "../ImageWithAuth/ImageWithAuth";
import "./TemplatesList.scss";

export const viewTypes = Object.freeze({
  LIST: "list",
  MODULE: "module",
});

const TemplatesList = React.forwardRef(
  (
    {
      templatesList,
      view,
      groups,
      templateListWithCheckStatus,
      currentRequestPage,
      onGetGroups: getGroups,
      onDelete: deleteTemplateHandler,
      onEdit: editTemplateHandler,
      onPageChange: pageChangeHandler,
      onRecognizeById: recognizeByIDHandler,
      onDeleteByEditingPopUp: deleteByEditingPopUp,
      onDuplicateByEditingPopUp: duplicateByEditingPopUp,
      onSetTemplateListWithCheck: setTemplateListWithCheckStatus,
      onMoveTemplatesToCertainGroup: moveTemplatesToCertainGroup,
      onCreateNewGroup: createNewGroup,
      onDeleteGroup: deleteGroup,
      onEditGroup: editGroup,
    },
    ref
  ) => {
    const [template, setTemplate] = useState(null);
    const imageWrapper = useRef();

    useOnClickOutside(imageWrapper, () => setTemplate(null));

    useEffect(() => {
      if (templatesList.length) {
        setTemplateListWithCheckStatus((templateListWithCheckStatus) => {
          templatesList.forEach((newTemplate) => {
            const existedTemplate = templateListWithCheckStatus.find(
              (template) => template.id === newTemplate.id
            );
            if (existedTemplate) {
              return;
            } else {
              templateListWithCheckStatus.push({
                ...newTemplate,
                checked: false,
                page: currentRequestPage,
              });
            }
          });

          return templateListWithCheckStatus;
        });
      }
    }, [setTemplateListWithCheckStatus, templatesList, currentRequestPage]);

    const checkBoxChangeHandler = (e, template) => {
      e.persist();

      setTemplateListWithCheckStatus((templateListWithCheckStatus) =>
        templateListWithCheckStatus.map((item) => {
          if (item.id === template.id) item.checked = !item.checked;
          return item;
        })
      );
    };

    const toggleAllCheckBoxHandler = () => {
      setTemplateListWithCheckStatus((templateListWithCheckStatus) => {
        let updated;
        const isSomeCheckBoxActive = templateListWithCheckStatus.find(
          (template) => template.checked && template.page === currentRequestPage
        );

        if (isSomeCheckBoxActive) {
          updated = templateListWithCheckStatus.map((item) => {
            if (item.page === currentRequestPage) {
              item.checked = false;
            }
            return item;
          });
        } else {
          updated = templateListWithCheckStatus.map((item) => {
            if (item.page === currentRequestPage) {
              item.checked = true;
            }
            return item;
          });
        }
        return updated;
      });
    };

    return (
      <>
        {/*listView component*/}
        {view === viewTypes.LIST && (
          <ListView
            ref={ref}
            groups={groups}
            templatesList={templatesList}
            currentRequestPage={currentRequestPage}
            templateListWithCheckStatus={templateListWithCheckStatus}
            onGetGroups={getGroups}
            onChangeCheckBoxStatus={checkBoxChangeHandler}
            onToggleAllCheckBoxHandler={toggleAllCheckBoxHandler}
            onEditTemplate={editTemplateHandler}
            onShowTemplateImage={setTemplate}
            onDeleteTemplate={deleteTemplateHandler}
            onDeleteByEditingPopUp={deleteByEditingPopUp}
            onDuplicateByEditingPopUp={duplicateByEditingPopUp}
            onRecognizeById={recognizeByIDHandler}
            onMoveTemplatesToCertainGroup={moveTemplatesToCertainGroup}
            onCreateNewGroup={createNewGroup}
            onDeleteGroup={deleteGroup}
            onEditGroup={editGroup}
          />
        )}
        {/*end of listView component*/}

        {/*moduleView component*/}
        {view === viewTypes.MODULE && (
          <div id="moduleView" className="module-view p-3">
            {templatesList.map((template) => (
              <TemplateItem
                key={template.id}
                template={template}
                onDeleteTemplate={deleteTemplateHandler}
                onEditTemplate={editTemplateHandler}
                onPageChange={pageChangeHandler}
              />
            ))}
          </div>
        )}
        {/*end of moduleView component*/}

        {template && (
          <div className={"template-preview"} ref={imageWrapper}>
            {/* <h3 className="text-center">{template.name}</h3> */}
            <ImageWithAuth
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              url={template.template_image}
            />
          </div>
        )}
      </>
    );
  }
);

export default TemplatesList;
