import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import "./SupportModal.scss";
import {useTranslation} from "react-i18next";
import {ReactComponent as DocManIcon} from "../../../assets/SupportIllustrations/docman.svg";

const SupportModal = ({toggle, modal}) => {
  const {t} = useTranslation();
  const [paid, setPaid] = useState(false);
  const [stripeLink, setStripeLink] = useState(process.env.REACT_APP_STRIPE_DEFAULT_PAY_LINK);

  const SupportOnClick = (e) => {
    e.preventDefault();
    window.open(
      stripeLink,
      t("support.title"),
      "resizable,scrollbars,status"
    );
    setPaid(true);
  }

  return (
    <Modal className={"support-modal__container"} centered isOpen={modal} toggle={toggle}>
      <ModalHeader className={"support-modal__header"} toggle={toggle}>
        {t("support.title")}
      </ModalHeader>
      <ModalBody>
        <div className={"support-modal__content-container"}>
          <div className={"support-modal__content-container__description"}>
            <DocManIcon className={"support-modal__docman-icon"}/>
            <div>
              {paid ? t("support.success") : t("support.description-part-1")}{" "}
              <div className="m-0">{paid ? null : t("support.description-part-2")}</div>
            </div>
          </div>
          {!paid ? (
            <div className={"support-modal__set-paid-container"}>
              <span>{t("support.summ-description")}</span>
                <select defaultValue={process.env.REACT_APP_STRIPE_DEFAULT_PAY_LINK}
                        onChange={(e) => setStripeLink(e.target.value)}>
                    <option value={process.env.REACT_APP_STRIPE_PAY_LINK_5}>$5</option>
                    <option value={process.env.REACT_APP_STRIPE_PAY_LINK_10}>$10</option>
                    <option value={process.env.REACT_APP_STRIPE_PAY_LINK_50}>$50</option>
                    <option value={process.env.REACT_APP_STRIPE_PAY_LINK_100}>$100</option>
                    <option value={process.env.REACT_APP_STRIPE_PAY_LINK_500}>$500</option>
                </select>
            </div>
          ) : null}
        </div>
        <div className={"support-modal__buttons-container"}>
          {paid ? (
            <Button className={"support-modal__ok-button"} onClick={toggle}>
              Ok
            </Button>
          ) : (
            <>
              <Button onClick={SupportOnClick} className={"support-modal__support-button"}>
                {t("support.support-button")}
              </Button>
              <Button onClick={toggle} className={"support-modal__cancel-button"}>
                {t("modal.cancel")}
              </Button>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SupportModal;
