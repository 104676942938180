import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import {loginUser, googleAuth} from "../../redux/feature/authV2/actions";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {SignInScheme} from "../../formik/validate";
import "./Forms.scss";
import NonDesktopSignInComponent from "./NonDesktop/SignIn";
import DesktopSignInComponent from "./Desktop/SignIn";
import {isMobile, isTablet} from "react-device-detect";
import {useGlobalTheming} from "../../hooks/useGlobalTheming";

const PRIVACY_URL =
  "https://drive.google.com/file/d/1kLmUEHc1TuGs8G9VWxfChSJMIeToFexR/view?usp=sharing";

const SignIn = () => {
  const [apiErrors, setApiErrors] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  useGlobalTheming();

  const isNonDesktopDevice = isMobile || isTablet;

  //Get information from location state to recognize link from previous page
  //If previous page send additional information - show this information as an alert
  const isAlertShown = location.state ? !!location.state.success : false;
  const alertText = location.state
    ? location.state.success
      ? location.state.success.message
      : ""
    : "";

  //Remove alert after 3 sec.
  useEffect(() => {
    sessionStorage.removeItem("passwordReload");
    if (isAlertShown) {
      setTimeout(() => history.replace(), 3000);
    }
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInScheme,
    onSubmit: () => {
      const values = {...formik.values, email: formik.values.email};
      dispatch(loginUser(values)).then((data) => {
        if (data.error) {
          setApiErrors(data.payload);
        }
      });
    },
  });

  const goToSignUp = () => {
    history.push("/signup");
  };

  const goToReset = () => {
    history.push("/reset");
  };

  const googleAuthHandler = () => {
    const {gapi} = window;

    gapi.auth2.authorize(
      {
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        response_type: "code",
      },
      function (response) {
        if (response.error) {
          alert(response.error);
          return;
        }

        const code = response.code;

        dispatch(googleAuth({code, redirectUri: window.location.origin}));
      }
    );
  };

  return isNonDesktopDevice ? (
    <NonDesktopSignInComponent
      alertText={alertText}
      isAlertShown={isAlertShown}
      formik={formik}
      apiErrors={apiErrors}
      privacyUrl={PRIVACY_URL}
      onGoogleAuth={googleAuthHandler}
      onGoToSignUp={goToSignUp}
      onGoToReset={goToReset}
    />
  ) : (
    <DesktopSignInComponent
      alertText={alertText}
      isAlertShown={isAlertShown}
      formik={formik}
      apiErrors={apiErrors}
      privacyUrl={PRIVACY_URL}
      onGoogleAuth={googleAuthHandler}
      onGoToSignUp={goToSignUp}
      onGoToReset={goToReset}
    />
  );
};

export default SignIn;
