import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import {MainColors} from "../../constants/CommonProperties";
import "./RecognitionTextAreasField.scss";
import DblClickEditTextArea from "../DblClickEditTextArea/DblClickEditTextArea";
import {truncateString} from "../../utils/index";

const styleArr = {
  selectedRow: {
    backgroundColor: MainColors.purplePrimary.purpleClick,
    borderBottom: `1px solid ${MainColors.purplePrimary.purplePrimary}`,
    width: "100%",
  },
  hoveredRow: {
    backgroundColor: MainColors.purplePrimary.purpleHover,
    borderBottom: `1px solid ${MainColors.purplePrimary.purplePrimary}`,
    width: "100%",
  },
  editedRow: {
    borderBottom: "1px solid white",
    backgroundColor: "white",
    width: "100%",
  },
  selectedInput: {
    backgroundColor: MainColors.purplePrimary.purpleClick,
  },
};

const RecognitionTextAreasField = React.forwardRef(
  (
    {
      recognitionResults,
      highlightAreas,
      onEditResults: editResults,
      onHighlightArea: highlightArea,
    },
    ref
  ) => {
    const [isBunBorder, setIsBunBorder] = useState({});

    const bunBorder = (index) => {
      setIsBunBorder({index, status: true});
    };

    const unbunBorder = (index) => {
      setTimeout(() => {
        setIsBunBorder({index, status: false});
      }, 200);
    };

    const getRowStyle = (item, index) => {
      if (isBunBorder.index === index && isBunBorder.status) return styleArr.editedRow;
      if (item.cid === highlightAreas.selected) return styleArr.selectedRow;
      if (item.cid === highlightAreas.hovered) return styleArr.hoveredRow;
      return {width: "100%"};
    };

    return (
      <>
        <Row id="result-values-row" noGutters className="recognition-text-area__field">
          {recognitionResults?.texts?.map((item, index) => (
            <Row
              id={`data-result-area${index}`}
              key={item.cid}
              className="recognition-text-area__result-area"
              noGutters
              data-area
              style={getRowStyle(item, index)}
              onMouseEnter={() => highlightArea(item.cid, "hover")}
              onMouseLeave={() => highlightArea(null, "hover")}
              onClick={() => highlightArea(item.cid, "click")}
            >
              <Col xs={3}>
                <p title={item.name} className={"result-area__paragraph pl-3"}>
                  {truncateString(item.name || "", 10)}
                </p>
              </Col>
              <Col xs={9}>
                <DblClickEditTextArea
                  value={item.text}
                  cid={item.cid}
                  spellCheck="false"
                  onFocus={() => bunBorder(index)}
                  onBlur={() => unbunBorder(index)}
                  onChange={editResults}
                />
              </Col>
            </Row>
          ))}
        </Row>
      </>
    );
  }
);

export default RecognitionTextAreasField;
