import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  editRecognitionResults,
  deleteRecognitionResultsArea,
  clearAllHighlightedAreas,
  highlightArea,
  returnOneActionBack,
  returnOneActionForward,
  setIsDocumentWereEdit,
} from "../../redux/feature/recognitionV2";
import {
  saveRecognitionResults,
  recognize,
  recognizeAsync,
} from "../../redux/feature/recognitionV2/actions";
import {logout} from "../../redux/feature/authV2/actions";
import {Spinner, Button} from "reactstrap";
import {TemplateGroupIcon} from "../../components/Icons";
import {Col, Row} from "reactstrap";
import {Container} from "reactstrap";
import {useHistory} from "react-router-dom";
import RecognitionTextAreasField from "../../components/RecognitionTextAreasField/RecognitionTextAreasField";
import {MainColors} from "../../constants/CommonProperties";
import CustomSearchDropDown from "../../components/CustomSearchDrowDown";
import RecognitionCanvas from "../../components/RecognitionCanvas/RecognitionCanvas";
import {
  useOnClickOutsideCanvasAreas,
  useOnClickOutsideDocumentAreas,
} from "../../hooks/useOnClickOutside";
import {capitalize, capitalizeFirstLetter, convertDataForCSVLink} from "../../utils";
import {useTranslation} from "react-i18next";
import "./Recognize.scss";
import ExportFileButton from "../../components/Buttons/ExportFileButton/ExportFileButton";
import ActionsManagePanel from "../../components/TemplateEditor/ActionsManagePanel";
import WarningModal from "../../components/WarningModal/WarningModal";
import RecognizeHeader from "../../components/DefaultLayout/RecognizeHeader/RecognizeHeader";
import usePrevious from "../../hooks/usePrevious";
import WizardLayout from "../../components/WizardLayout";
import {useNode} from "../../hooks/useNode";
import RefWrapper from "../../components/RefWrapper";
import {isMobile, isTablet} from "react-device-detect";
import NonDesktopHeader from "../../components/DefaultLayout/NonDesktopHeader";
import {truncateString} from "../../utils/index";
import ModalService from "../../modules/modals/services/ModalService";
import {useGlobalTheming} from "../../hooks/useGlobalTheming";
import {fetchTemplates} from "../../redux/feature/templatesV2/actions";
import AiWarningModal from "../../components/AiWarningModal/AiWarningModal";

const RecognitionResults = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isNonDesktop = isMobile || isTablet;
  useGlobalTheming();

  //--------------wizard data--------------------------------------------------
  const [wizardFirstTargetNode, wizardFirstTargetRef] = useNode();
  const [wizardSecondTargetNode, wizardSecondTargetRef] = useNode();
  const [wizardThirdTargetNode, wizardThirdTargetRef] = useNode();

  const [wizardStep, setWizardStep] = useState(0);
  const [wizardTarget, setWizardTarget] = useState(null);
  const [wizardRadius, setWizardRadius] = useState("0px");
  const [wizardHelperText, setWizardHelperText] = useState("");
  const [wizardXHelperText, setWizardXHelperText] = useState(0);
  const [wizardYHelperText, setWizardYHelperText] = useState(0);
  const [wizardNextStepFunction, setWizardNextStepFunction] = useState(null);
  const [isWizardLastAction, setIsWizardLastAction] = useState(false);
  //---------------------------------------------------------------------------

  const [isAiWarningOpen, setIsAiWarningOpen] = useState(false);
  const [aiWarnignDontShowAgain, setAiWarningDontShowAgain] = useState(false);

  const recognitionResults = useSelector(
    (state) => state.recognitionV2.recognitionResultsCurrentData.data
  );
  const initialRecognitionResults = useSelector(
    (state) => state.recognitionV2.recognitionResultsInitialData.data
  );
  const highlightAreas = useSelector((state) => state.recognitionV2.highlightedAreas);
  const file = useSelector((state) => state.recognitionV2.file);
  const templates = useSelector((state) => state.templatesV2.items);
  const isLoading = useSelector(
    (state) => state.recognitionV2.recognitionResultsCurrentData.isLoading
  );
  const isDocumentWereEdit = useSelector(
    (state) => state.recognitionV2.isDocumentWereEdit
  );
  const backlog = useSelector((state) => state.recognitionV2.dataBackLog);
  const canceledActions = useSelector((state) => state.recognitionV2.canceledActions);

  const stageRef = useRef();

  const [isTemplateGroupPopUpActive, setIsTemplateGroupPopUpActive] = useState(false);

  const recognizeByAIWithWarningHanlder = () => {
    if (aiWarnignDontShowAgain) {
      localStorage.setItem("parsio.recognizeByAiHideWarning", true);
    }
    const formData = new FormData();
    formData.append("file", file);

    dispatch(recognizeAsync(formData)).then(() => {
      history.push("/recognition/results");
    });
  };

  const recognizeByTemplateIDHandler = (templateID, isRecognizeByAI) => {
    if (recognitionResults.template.id === templateID) {
      //bun repeated recognition with current template ID
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    if (!isRecognizeByAI) {
      dispatch(recognize({formData, templateID, isTemplateWasChanged: true}));
    } else {
      if (!localStorage.getItem("parsio.recognizeByAiHideWarning")) {
        setIsAiWarningOpen(true);
      } else {
        dispatch(recognizeAsync(formData)).then(() => {
          history.push("/recognition/results");
        });
      }
    }
  };

  const saveResultsHandler = () => {
    dispatch(saveRecognitionResults(recognitionResults)).then(() => {
      if (!localStorage.getItem("parsio.skipWizardStatus")) {
        skipWizard();
      }
      history.push("/recognition/results");
    });
  };

  const editResultsHandler = (regionID, value) => {
    dispatch(editRecognitionResults({regionID, value}));
  };

  const deleteRecognitionResultsAreaHandler = (regionID) => {
    dispatch(deleteRecognitionResultsArea(regionID));
    dispatch(clearAllHighlightedAreas());
  };

  const highlightAreaHandler = (areaID, type) => {
    dispatch(highlightArea({areaID, type}));
  };

  const cancelAllActions = () => {
    showWarningModal({whereToGoAfterAccept: "/recognition/results"});
  };

  const acceptCancelAllActions = (whereToGoAfterAccept) => {
    const leaveSystem = () => {
      dispatch(logout()).then((data) => {
        if (data.error) {
          alert(data.payload);
        }
      });
    };

    if (whereToGoAfterAccept === "logout") {
      dispatch(saveRecognitionResults(initialRecognitionResults)).then((data) => {
        if (!data.error) {
          leaveSystem();
          ModalService.close();
        } else {
          alert("Something is going wrong. Try later.");
        }
      });
      //unnecessary 'return' statement
      return;
    } else {
      dispatch(saveRecognitionResults(initialRecognitionResults)).then((data) => {
        if (data.error) {
          alert("Something is going wrong. Try later.");
          return;
        }
        ModalService.close();
        history.push(whereToGoAfterAccept);
      });
    }
  };

  const showWarningModal = ({whereToGoAfterAccept}) => {
    ModalService.open(WarningModal, {
      headerText: capitalizeFirstLetter(t("recognize.warning-modal-title")),
      bodyText: capitalizeFirstLetter(t("recognize.warning-modal-text")),
      leftButtonText: t("recognize.warning-left-button-text").toLocaleUpperCase(),
      rightButtonText: t("recognize.warning-right-button-text").toLocaleUpperCase(),
      onClickAccept: () => acceptCancelAllActions(whereToGoAfterAccept),
    });
  };

  const skipWizard = () => {
    localStorage.setItem("parsio.skipWizardStatus", true);
  };

  useOnClickOutsideDocumentAreas("data-area", () => {
    dispatch(clearAllHighlightedAreas());
  });
  useOnClickOutsideCanvasAreas(stageRef.current, () => {
    dispatch(clearAllHighlightedAreas());
  });

  useEffect(() => {
    if (!file) {
      history.push("recognition/results");
    }
  }, [file, history]);

  useEffect(() => {
    dispatch(setIsDocumentWereEdit(false));
  }, [dispatch]);

  //for autosaving - only if backlog changed. It means that user made some changes.
  const prevBackLog = usePrevious(backlog);
  useEffect(() => {
    if (prevBackLog && backlog !== prevBackLog) {
      dispatch(saveRecognitionResults(recognitionResults));
    }
  }, [backlog, dispatch, prevBackLog, recognitionResults]);

  useEffect(() => {
    //show warning when want to reload or leave page
    const innerFunc = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", innerFunc);

    return function cleanUp() {
      window.removeEventListener("beforeunload", innerFunc);
    };
  }, []);

  useEffect(() => {
    switch (wizardStep) {
      case 0:
        if (wizardFirstTargetNode) {
          setWizardTarget(wizardFirstTargetNode);
          setWizardRadius("48%");
          setWizardHelperText([
            t("wizard.recognition-recognize-page-text1-part1"),
            t("wizard.recognition-recognize-page-text1-part2"),
          ]);
          setWizardNextStepFunction({
            nextStep: () => setWizardStep(1),
          });
          setWizardXHelperText(55);
          setWizardYHelperText(65);
        }
        break;
      case 1:
        if (wizardSecondTargetNode) {
          setWizardTarget(wizardSecondTargetNode);
          setWizardRadius("160px");
          setWizardHelperText([t("wizard.recognition-recognize-page-text2")]);
          setWizardNextStepFunction({
            nextStep: () => setWizardStep(2),
          });
          setWizardXHelperText(55);
          setWizardYHelperText(10);
        }
        break;
      case 2:
        setWizardTarget(wizardThirdTargetNode);
        setWizardRadius("150px");
        setWizardXHelperText(45);
        setWizardYHelperText(70);
        setWizardHelperText([
          t("wizard.recognition-recognize-page-text3-part1"),
          t("wizard.recognition-recognize-page-text3-part2"),
        ]);
        setWizardNextStepFunction(null);
        setIsWizardLastAction(true);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardStep, wizardFirstTargetNode, wizardSecondTargetNode, wizardThirdTargetNode]);

  return (
    <WizardLayout
      targetNode={wizardTarget}
      radius={wizardRadius}
      xHelperText={wizardXHelperText}
      yHelperText={wizardYHelperText}
      helperTextList={wizardHelperText}
      onNextStep={wizardNextStepFunction?.nextStep}
      isLastAction={isWizardLastAction}
    >
      {isNonDesktop ? (
        <NonDesktopHeader linkTitle={t("header.recognition")} />
      ) : (
        <RecognizeHeader onShowWarningModal={showWarningModal} />
      )}
      <Container fluid>
        <RefWrapper ref={wizardFirstTargetRef} className="ref-wrapper">
          <Row
            id="main-row"
            className={isNonDesktop ? "main-row-non-desktop" : "main-row"}
            noGutters
          >
            <Col
              xs={12}
              className={
                isNonDesktop
                  ? "main-row-non-desktop__left-column"
                  : "main-row__left-column"
              }
            >
              <RefWrapper className={"wrapper__container"}>
                <div
                  className={
                    isNonDesktop
                      ? "left-column-non-desktop__upper-div"
                      : "left-column__upper-div"
                  }
                >
                  <span className={"left-column__template-name"}>
                    {truncateString(recognitionResults?.template?.name || "", 15)}
                  </span>
                  <CustomSearchDropDown
                    component={
                      <div className="cursor-pointer">
                        <TemplateGroupIcon
                          width={isNonDesktop ? "18px" : "12px"}
                          height={isNonDesktop ? "18px" : "12px"}
                          className="mr-2"
                          fill={
                            isTemplateGroupPopUpActive
                              ? MainColors.purplePrimary.purplePrimary
                              : MainColors.greyPalette.grey6
                          }
                        />
                        <span
                          className={
                            (isNonDesktop
                              ? "left-column-non-desktop__group-pop-up "
                              : "left-column__group-pop-up ") +
                            (isTemplateGroupPopUpActive
                              ? `${
                                  isNonDesktop ? "left-column-non-desktop" : "left-column"
                                }__group-pop-up_active`
                              : "")
                          }
                        >
                          {capitalize(t("recognize.change-template"))}
                        </span>
                      </div>
                    }
                    data={templates}
                    onChoose={recognizeByTemplateIDHandler}
                    onSetActive={setIsTemplateGroupPopUpActive}
                    onSearchTemplates={(args) => dispatch(fetchTemplates(args))}
                  />
                </div>
                <div className={"left-column__file-name"}>
                  <p className="pl-3 m-0">{decodeURI(file?.name) || ""}</p>
                  {recognitionResults?.texts && (
                    <RefWrapper ref={wizardSecondTargetRef}>
                      <div className={`cursor-pointer ${isNonDesktop ? "" : "p-3"}`}>
                        <ExportFileButton
                          iconWidth={isNonDesktop ? "20px" : ""}
                          iconHeight={isNonDesktop ? "20px" : ""}
                          data={convertDataForCSVLink(recognitionResults?.texts || [])}
                        />
                      </div>
                    </RefWrapper>
                  )}
                </div>
                {isLoading ? (
                  <div className="left-column__spinner-container p-4">
                    <Spinner />
                    <p className="mt-3 spinner-container__label">
                      {capitalize(t("recognize.recognition"))}...
                    </p>
                  </div>
                ) : (
                  <>
                    <RecognitionTextAreasField
                      recognitionResults={recognitionResults}
                      highlightAreas={highlightAreas}
                      onEditResults={editResultsHandler}
                      onHighlightArea={highlightAreaHandler}
                    />
                    <div
                      className={
                        isNonDesktop
                          ? "main-row-non-desktop__buttons-container p-3"
                          : "main-row__buttons-container p-3"
                      }
                    >
                      <Button
                        size="sm"
                        className="main-row-non-desktop__cancel-all-actions-button"
                        color="link"
                        disabled={!isDocumentWereEdit}
                        onClick={cancelAllActions}
                      >
                        {t("edit-box.cancel-all-changes").toLocaleUpperCase()}
                      </Button>
                      <RefWrapper
                        ref={wizardThirdTargetRef}
                        className={"main-row-non-desktop__save-button"}
                      >
                        <Button size="sm" color="primary" onClick={saveResultsHandler}>
                          {t("recognition-edit-box.save-results").toUpperCase()}
                        </Button>
                      </RefWrapper>
                    </div>
                  </>
                )}
              </RefWrapper>
            </Col>
            <Col
              xs={12}
              className={
                isNonDesktop
                  ? "main-row-non-desktop__right-column"
                  : "main-row__right-column"
              }
            >
              <ActionsManagePanel
                className="recognition-manage-actions-panel"
                canceledActions={canceledActions}
                backlog={backlog}
                onReturnOneActionBack={() => dispatch(returnOneActionBack())}
                onReturnOneActionForward={() => dispatch(returnOneActionForward())}
              />
              <RecognitionCanvas
                ref={stageRef}
                imageUrl={recognitionResults?.image_url}
                areas={recognitionResults?.texts}
                highlightAreas={highlightAreas}
                onHighlightArea={highlightAreaHandler}
                onDeleteResultsArea={deleteRecognitionResultsAreaHandler}
              />
            </Col>
          </Row>
        </RefWrapper>
        <AiWarningModal
          isOpen={isAiWarningOpen}
          toogle={() => setIsAiWarningOpen(false)}
          headerText={capitalizeFirstLetter(t("results.warning-modal-header"))}
          bodyText={capitalizeFirstLetter(t("results.warning-modal-body"))}
          checkboxText={capitalizeFirstLetter(t("results.warning-modal-checkbox"))}
          checked={aiWarnignDontShowAgain}
          onChange={(e) => setAiWarningDontShowAgain(e.target.checked)}
          onAccept={recognizeByAIWithWarningHanlder}
        />
      </Container>
    </WizardLayout>
  );
};

export default RecognitionResults;
