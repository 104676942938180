import React, {useState, useEffect} from "react";
import CreateNewInstanceField from "../../../components/CreateNewInstanceField/CreateNewInstanceField";
import DefaultLayout from "../../../components/DefaultLayout/DefaultLayout";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {fetchTemplates} from "../../../redux/feature/templatesV2/actions";
import {createNewDocument} from "../../../redux/feature/docTemplateEditorV2";
import {
  recognize,
  recognizeAsync,
  getDocuments,
  deleteDocument,
} from "../../../redux/feature/recognitionV2/actions";
import {
  saveTemplate,
  loadTemplate,
} from "../../../redux/feature/docTemplateEditorV2/actions";
import {setFileToRecognize} from "../../../redux/feature/recognitionV2";
import {
  NoRecognitionResultsIcon,
  VisibilityIcon,
  NewRecognitionIcon,
} from "../../../components/Icons";
import {Button, Spinner} from "reactstrap";
import {MainColors} from "../../../constants/CommonProperties";
import RecognitinResultsList from "../RecognitionResultList/RecognitionResultList";
import ConfirmModal from "../../../components/ConfrimModal/ConfirmModal";
import NoTemplateFoundModal from "../../../components/NoTemplateFoundedModal/NoTemplateFoundModal";
import UsePagination from "../../../components/UsePagination";
import {useTranslation} from "react-i18next";
import {
  setFileUploadPopUpFile,
  clearFileUploadPopUpFile,
  showPopUp,
} from "../../../redux/feature/fileUploadField";
import {
  capitalize,
  capitalizeFirstLetter,
  processImageFile,
  processPdfFile,
} from "../../../utils";
import findPageToRequest from "../../../utils/findPageToRequest";
import WizardLayout from "../../../components/WizardLayout";
import {useNode} from "../../../hooks/useNode";
import "./Results.scss";
import {default as RecognitionFailedModal} from "../../../components/NoTemplateFoundedModal/NoTemplateFoundModal";
import RefWrapper from "../../../components/RefWrapper";
import MobileRecognitionResultsList from "../MobileRecognitionResultsList";
import {isMobile, isTablet} from "react-device-detect";
import {useGlobalTheming} from "../../../hooks/useGlobalTheming";
import AiWarningModal from "../../../components/AiWarningModal/AiWarningModal";

import ModalService from "../../../modules/modals/services/ModalService";

const RecognitionResults = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isNonDesktop = isMobile || isTablet;
  useGlobalTheming();

  const templatesList = useSelector((state) => state.templatesV2.items);
  const documents = useSelector((state) => state.recognitionV2.documents.data);
  const documentRequestPage = useSelector((state) => state.recognitionV2.documents.page);
  const file = useSelector((state) => state.recognitionV2.file);
  const documentsCount = useSelector((state) => state.recognitionV2.documents.count);
  const documentsLimitPerRequest = useSelector(
    (state) => state.recognitionV2.documents.limit
  );
  const currentRequestPage = useSelector((state) => state.recognitionV2.documents.page);
  const fileUploadFieldData = useSelector((state) => state.fileUploadField.recognition);

  const [isAiWarningOpen, setIsAiWarningOpen] = useState(false);
  const [aiWarnignDontShowAgain, setAiWarningDontShowAgain] = useState(false);
  const [failedAIRecognitions, setFailedAIRecognitions] = useState([]);

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [errorText, setErrorText] = useState(null);

  const [isShow404Warning, setShow404Warning] = useState(false);
  const [isRecognizeLoading, setIsRecognizeLoading] = useState(false);
  const [documentsListWithCheckStatus, setdocumentsListWithCheckStatus] = useState([]);

  //duplicate code fragment
  const [isNewDocumentUploading, setIsNewDocumentUploading] = useState(false);
  const [isDocumentsWasDeletedByPopUp, setIsDocumentsWasDeletedByPopUp] = useState(false);

  //--------------wizard data--------------------------------------------------
  const [
    wizardFirstScenarioFirstTargetNode,
    wizardFirstScenarioFirstTargetRef,
  ] = useNode();

  const [
    wizardFirstScenarioSecondTargetNode,
    wizardFirstScenarioSecondTargetRef,
  ] = useNode();

  const [
    wizardFirstScenarioFirstPointerNode,
    wizardFirstScenarioFirstPointerRef,
  ] = useNode();

  const [
    wizardSecondScenarioFirstTargetNode,
    wizardSecondScenarioFirstTargetRef,
  ] = useNode();

  const [
    wizardSecondScenarioSecondTargetNode,
    wizardSecondScenarioSecondTargetRef,
  ] = useNode();

  const [
    wizardSecondScenarioThirdTargetNode,
    wizardSecondScenarioThirdTargetRef,
  ] = useNode();

  const [
    wizardSecondScenarioSecondPointerNode,
    wizardSecondScenarioSecondPointerRef,
  ] = useNode();

  const [wizardStep, setWizardStep] = useState(0);
  const [wizardScenario, setWizardScenario] = useState(0);
  const [wizardTarget, setWizardTarget] = useState(null);
  const [wizardPointer, setWizardPointer] = useState(null);
  const [wizardRadius, setWizardRadius] = useState("0px");
  const [wizardHelperText, setWizardHelperText] = useState("");
  const [wizardXHelperText, setWizardXHelperText] = useState(0);
  const [wizardYHelperText, setWizardYHelperText] = useState(0);
  const [wizardDisableAllArea, setWizardDisableAllArea] = useState(false);
  //---------------------------------------------------------------------------

  const createNewDocumentHandler = (argFile) => {
    if (isNonDesktop) {
      setShow404Warning(false);
      return;
    }
    const file = argFile || fileUploadFieldData.file;

    const handleFileIsReady = (...fileProperties) => {
      dispatch(
        createNewDocument({
          image: fileProperties[0],
          imageBlob: fileProperties[1],
          fileName: fileProperties[2],
        })
      );
      // формируем пустой документ для сохранения и получения ID
      const document = {
        width: fileProperties[0].width,
        height: fileProperties[0].height,
        image: fileProperties[0],
        imageBlob: fileProperties[1],
        fileName: fileProperties[2],
        templateName: "Template name #",
        group: null,
      };
      // сохраняем пустой документ и получаем ID
      setIsNewDocumentUploading(true);
      dispatch(saveTemplate({document, areas: []})).then((data) => {
        if (!data.error) {
          //нужно для поддержки повторяющихся имен шаблонов. Т.е. сохраняем шаблон,
          //потом получаем его же и сохраняем в стейт.
          //duplicate code fragment
          dispatch(loadTemplate(data.payload.id)).then(() => {
            if (!data.error) {
              dispatch(clearFileUploadPopUpFile({page: "recognition"}));
              dispatch(showPopUp({status: false, page: "recognition"}));
              //------------------------------------
              setIsNewDocumentUploading(false);
              history.push("/new");
            } else {
              alert("Something is going wrong. Try later.");
            }
          });
          //clear fileAploadFiledData
          dispatch(clearFileUploadPopUpFile({page: "recognition"}));
          dispatch(showPopUp({status: false, page: "recognition"}));
          //------------------------------------
          setIsNewDocumentUploading(false);
          history.push("/new");
        } else {
          alert("Something is going wrong. Try later.");
        }
        // setIsNewDocumentUploading(false);
      });
    };

    if (file.type === "application/pdf") {
      processPdfFile(file, handleFileIsReady);
    }
    if (file.type.includes("image")) {
      processImageFile(file, handleFileIsReady);
    }
  };

  const recognizeByTemplate = (arg) => {
    dispatch(showPopUp({status: false, page: "recognition"}));
    setIsRecognizeLoading(true);

    dispatch(recognize(arg))
      .then((data) => {
        if (data.error) {
          setIsRecognizeLoading(false);
          if (data.payload === "Appropriate close image template has not been found") {
            dispatch(setFileToRecognize(fileUploadFieldData.file));
            setShow404Warning(true);
            //wizard----------------
            setWizardStep(null);
            //----------------------
          } else setErrorText("Something is going wrong. Try later.");
        } else {
          dispatch(setFileToRecognize(fileUploadFieldData.file));
          history.push("/recognition/recognize");
        }
      })
      .catch(() => {
        setIsRecognizeLoading(false);
        setErrorText("Something is going wrong. Try later.");
      });
  };

  const recognizeByAI = (formData) => {
    //TODO: make it
    dispatch(showPopUp({status: false, page: "recognition"}));

    dispatch(recognizeAsync(formData))
      .then((data) => {
        if (data.error) {
          // if (data.payload === "Approproate close image template has not been found") {
          //   dispatch(setFileToRecognize(fileUploadFieldData.file));
          //   setShow404Warning(true);
          // } else setErrorText("Something is going wrong. Try later.");
        } else {
          dispatch(setFileToRecognize(fileUploadFieldData.file));
          dispatch(getDocuments({page: 0, limit: 8}));
        }
      })
      .catch(() => {
        setErrorText("Something is going wrong. Try later.");
      });
  };

  const recognizeByAIWithWarningHanlder = () => {
    if (aiWarnignDontShowAgain) {
      localStorage.setItem("parsio.recognizeByAiHideWarning", true);
    }
    setIsAiWarningOpen(false);
    const file = fileUploadFieldData.file;
    const formData = new FormData();
    formData.append("file", file);
    recognizeByAI(formData);
  };

  const uploadNewfile = (file) => {
    dispatch(setFileUploadPopUpFile({file, page: "recognition"}));

    if (wizardScenario === 0) {
      setWizardStep(1);
    }
  };

  const processUpload = (recognizeType) => {
    //------wizard logic---------------------------------------
    setWizardStep(2);
    //----------------------------------------------------------
    const file = fileUploadFieldData.file;
    const templateID = localStorage.getItem("recognizeByTemplateID") || "";
    const formData = new FormData();
    formData.append("file", file);

    if (recognizeType === "recognize using template") {
      recognizeByTemplate({formData, templateID});
    }
    if (recognizeType === "recognize using ai") {
      //TODO: when backend will be ready change it.
      if (!localStorage.getItem("parsio.recognizeByAiHideWarning")) {
        setIsAiWarningOpen(true);
      } else {
        recognizeByAI(formData);
      }
    }
  };

  const deleteDocumentsByEditingPopUp = () => {
    const acceptHandler = (documentsListWithCheckStatus) => {
      let promises = [];
      for (let document of documentsListWithCheckStatus) {
        if (document.checked) {
          promises.push(dispatch(deleteDocument(document.id)));
        }
      }
      Promise.all(promises)
        .then((data) => {
          if (data.error) {
            alert(t("template-editor.error-text"));
          } else {
            setdocumentsListWithCheckStatus([]);

            dispatch(
              getDocuments({
                page: findPageToRequest(documentsListWithCheckStatus, currentRequestPage),
                limit: 8,
              })
            ).then(() => setIsDocumentsWasDeletedByPopUp(true));
          }
        })
        .catch(() => alert(t("template-editor.error-text")))
        .finally(() => ModalService.close());
    };

    for (let document of documentsListWithCheckStatus) {
      if (document.checked) {
        ModalService.open(ConfirmModal, {
          headerText: capitalizeFirstLetter(t("results.modal-header")),
          saveButtonText: t("modal.delete").toLocaleUpperCase(),
          onAccept: () => acceptHandler(documentsListWithCheckStatus),
        });
        break;
      }
    }
  };

  const deleteTemplateHandler = (document) => {
    const {id} = document;

    ModalService.open(ConfirmModal, {
      headerText: capitalizeFirstLetter(t("results.modal-header")),
      saveButtonText: t("modal.delete").toLocaleUpperCase(),
      onAccept: () => acceptHandler(id),
    });
  };

  const acceptHandler = async (id) => {
    try {
      await dispatch(deleteDocument(id)).then((data) => {
        if (data.error) {
          alert(t("results.delete-error-text"));
        } else {
          //проверяем удаляли мы последний документ на странице пагинации
          if (documents.length === 1) {
            dispatch(
              getDocuments({
                page: currentRequestPage - 1 > 0 ? currentRequestPage - 1 : 0,
                limit: 8,
              })
            );
          } else {
            dispatch(
              getDocuments({
                page: currentRequestPage,
                limit: 8,
              })
            );
          }
        }
      });
    } catch {
      alert(t("results.delete-error-text"));
    } finally {
      ModalService.close();
    }
  };

  const showDocumentHandler = (document) => {
    if (document.status === "success") {
      history.push(`/recognition/recognize/${document.id}/`);
    }
  };

  const deleteFailedRecognitionsHandler = () => {
    let promises = [];
    for (let documentID of failedAIRecognitions) {
      promises.push(dispatch(deleteDocument(documentID)));
    }
    Promise.all(promises)
      .then(() => {
        dispatch(
          getDocuments({
            page: currentRequestPage,
            limit: 8,
          })
        );
        setFailedAIRecognitions([]);
      })
      .catch(() => alert(t("template-editor.error-text")));
  };

  //duplicate code fragment
  useEffect(() => {
    dispatch(fetchTemplates({page: 0, limit: 8}))
      .then((data) => {
        if (data.error) {
          setIsLoadingTemplates(false);
          setErrorText(t("results.server-error-text"));
        } else {
          setIsLoadingTemplates(false);
        }
      })
      .catch(() => {
        setIsLoadingTemplates(false);
        setErrorText(t("results.server-error-text"));
      });
  }, [dispatch, t]);

  //duplicate code fragment
  useEffect(() => {
    dispatch(getDocuments({page: 0, limit: 8}))
      .then((data) => {
        if (data.error) {
          setIsLoadingDocuments(false);
          setErrorText(t("results.server-error-text"));
        } else {
          setIsLoadingDocuments(false);
        }
      })
      .catch(() => {
        setIsLoadingDocuments(false);
        setErrorText(t("results.server-error-text"));
      });
  }, [dispatch, t]);

  useEffect(() => {
    //for AI async regonition (get documents ready status)
    let timerId = setInterval(() => {
      dispatch(getDocuments({page: currentRequestPage, limit: 8}))
        .then((data) => {
          if (data.error) {
            setErrorText(t("results.server-error-text"));
          }
        })
        .catch(() => {
          setErrorText(t("results.server-error-text"));
        });
    }, 5000);

    return function cleanUp() {
      clearInterval(timerId);
    };
  }, [dispatch, t, currentRequestPage]);

  useEffect(() => {
    if (localStorage.getItem("recognizeByTemplateID")) {
      dispatch(showPopUp({status: true, page: "recognition"}));
    }
    return () => localStorage.removeItem("recognizeByTemplateID");
  }, [dispatch]);

  useEffect(() => {
    //wizard
    if (!localStorage.getItem("parsio.skipWizardStatus")) {
      dispatch(setFileUploadPopUpFile({file: null, page: "recognition/results"}));
      documents.length ? setWizardScenario(1) : setWizardScenario(0);
    }
  }, [dispatch, setWizardScenario, documents.length]);

  useEffect(() => {
    switch (wizardStep) {
      //duplicate code fragment
      case 0:
        if (wizardFirstScenarioFirstTargetNode || wizardSecondScenarioFirstTargetNode) {
          setWizardTarget(
            wizardScenario === 0
              ? wizardFirstScenarioFirstTargetNode
              : wizardSecondScenarioFirstTargetNode
          );
          setWizardPointer(
            wizardScenario === 0
              ? wizardFirstScenarioFirstPointerNode
              : wizardSecondScenarioFirstTargetNode
          );
          setWizardRadius(wizardScenario === 0 ? "250px" : "100px");
          setWizardHelperText(
            wizardScenario === 0
              ? [t("wizard.recognition-results-page-text1")]
              : [t("wizard.recognition-results-page-text4")]
          );
          setWizardXHelperText(58);
          setWizardYHelperText(wizardScenario === 0 ? 20 : 40);
        }

        break;
      case 1:
        if (wizardFirstScenarioSecondTargetNode || wizardSecondScenarioSecondTargetNode) {
          setWizardTarget(
            wizardScenario === 0
              ? wizardFirstScenarioSecondTargetNode
              : wizardSecondScenarioSecondTargetNode
          );
          setWizardPointer(
            wizardScenario === 0
              ? wizardFirstScenarioSecondTargetNode
              : wizardSecondScenarioSecondPointerNode
          );
          setWizardRadius(wizardScenario === 0 ? "140px" : "270px");
          setWizardHelperText(
            wizardScenario === 0
              ? [
                  t("wizard.recognition-results-page-text3-part1"),
                  t("wizard.recognition-results-page-text3-part2"),
                  t("wizard.recognition-results-page-text3-part3"),
                  t("wizard.recognition-results-page-text3-part4"),
                ]
              : [t("wizard.recognition-results-page-text5")]
          );
          wizardScenario === 1 && setWizardYHelperText(72.5);
        }
        break;
      case 2:
        setWizardDisableAllArea(true);
        break;
      default:
        break;
    }
    //for second scenario when you need to show target on recognize using template
    if (wizardSecondScenarioThirdTargetNode && wizardStep && wizardStep !== 2) {
      setWizardTarget(wizardSecondScenarioThirdTargetNode);
      setWizardRadius("140px");
      setWizardPointer(wizardSecondScenarioThirdTargetNode);
      setWizardHelperText([
        t("wizard.recognition-results-page-text3-part1"),
        t("wizard.recognition-results-page-text3-part2"),
        t("wizard.recognition-results-page-text3-part3"),
        t("wizard.recognition-results-page-text3-part4"),
      ]);
      setWizardXHelperText(65);
      setWizardYHelperText(50);
    }
  }, [
    wizardStep,
    wizardFirstScenarioFirstTargetNode,
    wizardFirstScenarioFirstPointerNode,
    wizardFirstScenarioSecondTargetNode,
    wizardSecondScenarioFirstTargetNode,
    wizardSecondScenarioSecondTargetNode,
    wizardSecondScenarioThirdTargetNode,
    wizardSecondScenarioSecondPointerNode,
    wizardScenario,
    t,
  ]);

  return (
    <WizardLayout
      targetNode={wizardTarget}
      pointerNode={wizardPointer}
      radius={wizardRadius}
      xHelperText={wizardXHelperText}
      yHelperText={wizardYHelperText}
      helperTextList={wizardHelperText}
      disableAllArea={wizardDisableAllArea}
    >
      <DefaultLayout isNonDesktop={isNonDesktop} linkTitle={t("header.results")}>
        {(isLoadingTemplates || isLoadingDocuments) && (
          <div
            className={
              isNonDesktop ? "spinner-non-desktop__container" : "spinner__container"
            }
          >
            <Spinner />
          </div>
        )}
        {!isLoadingTemplates && !isLoadingDocuments && errorText && (
          <div
            className={
              isNonDesktop ? "spinner__container-non-desktop" : "spinner__container"
            }
          >
            <p>{errorText}</p>
          </div>
        )}
        {fileUploadFieldData.isPopUpOpen && documents.length && (
          <CreateNewInstanceField
            isPopUp
            ref={{
              wizardSecondScenarioSecondTargetRef,
              wizardSecondScenarioSecondPointerRef,
              wizardSecondScenarioThirdTargetRef,
            }}
            isChooseRecognitionButtonActive
            isRecognitionByTemplate={localStorage.getItem("recognizeByTemplateID")}
            isNewDocumentUploading={isNewDocumentUploading}
            isNonDesktop={isNonDesktop}
            icon={<NewRecognitionIcon />}
            file={fileUploadFieldData.file}
            headerText={capitalizeFirstLetter(t("results.add-document"))}
            buttonText={t("modal.choose-file").toLocaleUpperCase()}
            beforeButtonText={t("results.drag-n-drop-text")}
            // У коллбека разная сигнатура: в одном случае туда передается файл, а в другом режим типа "recognize with template". При этом
            // как функция вызывается определяеся с помощью проверки сложных флагов. Это очень запутанная реализация,
            // очень трудно понимать что где вызывается.
            // Сейчас баг поправлен наиболее быстрым образом, но это все хозяйство надо рефакторить. Код очень трудно поддерживать.
            onCreateNewDocument={processUpload}
            onProcessUpload={processUpload}
            onSetFile={(file) => uploadNewfile(file)}
            onClearFile={() => dispatch(clearFileUploadPopUpFile({page: "recognition"}))}
            onClosePopUp={() => dispatch(showPopUp({status: false, page: "recognition"}))}
          />
        )}
        {!isLoadingTemplates &&
          !isLoadingDocuments &&
          !errorText &&
          !templatesList.length &&
          !documents.length && (
            <CreateNewInstanceField
              notemplates
              icon={<NoRecognitionResultsIcon />}
              hiddenButton={isNonDesktop}
              isChooseRecognitionButtonActive
              isNewDocumentUploading={isNewDocumentUploading}
              file={fileUploadFieldData.file}
              headerText={capitalizeFirstLetter(t("results.first-template-header"))}
              beforeButtonText={
                isNonDesktop
                  ? capitalizeFirstLetter(t("results.start-recognition-non-desktop"))
                  : capitalizeFirstLetter(t("results.start-recognition"))
              }
              buttonText={t("modal.choose-file").toLocaleUpperCase()}
              onCreateNewDocument={createNewDocumentHandler}
              onProcessUpload={processUpload}
              onSetFile={(file) =>
                dispatch(setFileUploadPopUpFile({file, page: "recognition"}))
              }
              onClearFile={() =>
                dispatch(clearFileUploadPopUpFile({page: "recognition"}))
              }
            />
          )}
        {!isLoadingTemplates &&
          !isLoadingDocuments &&
          !errorText &&
          !!templatesList.length &&
          !documents.length && (
            /*no-results &&*/ <CreateNewInstanceField
              ref={{
                wizardFirstScenarioFirstTargetRef,
                wizardFirstScenarioFirstPointerRef,
                wizardFirstScenarioSecondTargetRef,
              }}
              icon={<NoRecognitionResultsIcon />}
              isChooseRecognitionButtonActive
              isNonDesktop={isNonDesktop}
              isNewDocumentUploading={isNewDocumentUploading}
              file={fileUploadFieldData.file}
              headerText={capitalizeFirstLetter(t("results.first-template-header"))}
              buttonText={t("modal.choose-file").toLocaleUpperCase()}
              beforeButtonText={
                isNonDesktop
                  ? capitalizeFirstLetter(t("results.start-recognition-non-desktop"))
                  : capitalizeFirstLetter(t("results.start-recognition"))
              }
              onProcessUpload={processUpload}
              onSetFile={(file) => uploadNewfile(file)}
              onClearFile={() =>
                dispatch(clearFileUploadPopUpFile({page: "recognition"}))
              }
            />
          )}
        {!isLoadingTemplates && !isLoadingDocuments && !errorText && !!documents.length && (
          <div style={{backgroundColor: MainColors.greyPalette.grey1}}>
            <div
              className={
                isNonDesktop
                  ? "results-non-desktop__container"
                  : "results__container  pt-3 pb-1"
              }
            >
              {!isNonDesktop && (
                <h3 className="m-0">{capitalize(t("results.results"))}</h3>
              )}
              <div>
                <RefWrapper ref={wizardSecondScenarioFirstTargetRef}>
                  <Button
                    className={
                      isNonDesktop ? "results-non-desktop__recognize-button" : " "
                    }
                    onClick={() => {
                      setWizardStep(1);
                      dispatch(showPopUp({status: true, page: "recognition"}));
                    }}
                    size="sm"
                    color="primary"
                  >
                    <VisibilityIcon
                      className="mr-2 mb-1"
                      width="12px"
                      height="12px"
                      fill="white"
                    />
                    {t("results.recognize").toLocaleUpperCase()}
                  </Button>
                </RefWrapper>
              </div>
            </div>
            <UsePagination
              pageCount={Math.ceil(documentsCount / documentsLimitPerRequest) || 0}
              currentRequestPage={currentRequestPage}
              cb={(page) => dispatch(getDocuments({page, limit: 8}))}
              className={
                isNonDesktop
                  ? "results-non-desktop__pagination pb-5"
                  : "results__pagination pt-3"
              }
            >
              {isNonDesktop ? (
                <MobileRecognitionResultsList
                  documents={documents}
                  onSetFailedAIRecognitions={setFailedAIRecognitions}
                  onDeleteDocument={deleteTemplateHandler}
                  onShowDocument={showDocumentHandler}
                />
              ) : (
                <RecognitinResultsList
                  documentsListWithCheckStatus={documentsListWithCheckStatus}
                  documents={documents}
                  documentRequestPage={documentRequestPage}
                  isDocumentsWasDeletedByPopUp={isDocumentsWasDeletedByPopUp}
                  onSetDocumentListWithCheckStatus={setdocumentsListWithCheckStatus}
                  onDeleteDocument={deleteTemplateHandler}
                  onDeleteDocumentsByEditingPopUp={deleteDocumentsByEditingPopUp}
                  onShowDocument={showDocumentHandler}
                  onSetFailedAIRecognitions={setFailedAIRecognitions}
                  onSetIsDocumentsWasDeletedByPopUp={setIsDocumentsWasDeletedByPopUp}
                />
              )}
            </UsePagination>
          </div>
        )}

        {isRecognizeLoading && !documents.length && (
          <div className="spinner__container spinner__container_flex-column p-5 text-center">
            <Spinner />
            <p className="mt-3 spinner__label">
              {capitalize(t("header.recognition"))}...
            </p>
            <p className="mt-3">{t("recognize.recognition-warning")}</p>
          </div>
        )}
        {isRecognizeLoading && documents.length && (
          <div className="spinner__container spinner__container_pop-up p-5 text-center">
            <Spinner />
            <p className="mt-3 spinner__label">
              {capitalize(t("header.recognition"))}...
            </p>
            <p className="mt-3">{t("recognize.recognition-warning")}</p>
          </div>
        )}
      </DefaultLayout>
      <NoTemplateFoundModal
        hiddenCancelButton={
          !localStorage.getItem("parsio.skipWizardStatus") || isNonDesktop
        }
        isOpen={isShow404Warning}
        showSpinner={isNewDocumentUploading}
        headerText={capitalizeFirstLetter(t("results.no-template-header"))}
        paragraphText={
          isNonDesktop
            ? t("results.no-template-text-non-desktop")
            : capitalizeFirstLetter(t("results.no-template-text"))
        }
        saveButtonText={isNonDesktop ? "OK" : t("modal.new-template").toLocaleUpperCase()}
        onAccept={() => createNewDocumentHandler(file)}
        onDecline={() => setShow404Warning(false)}
      />
      <RecognitionFailedModal
        isOpen={failedAIRecognitions.length}
        hiddenCancelButton
        headerText={t("recognition-failed-modal.header-text")}
        paragraphText={capitalizeFirstLetter(
          t("recognition-failed-modal.paragraph-text")
        )}
        saveButtonText="OK"
        onAccept={deleteFailedRecognitionsHandler}
      />
      <AiWarningModal
        isOpen={isAiWarningOpen}
        toogle={() => setIsAiWarningOpen(false)}
        headerText={capitalizeFirstLetter(t("results.warning-modal-header"))}
        bodyText={capitalizeFirstLetter(t("results.warning-modal-body"))}
        checkboxText={capitalizeFirstLetter(t("results.warning-modal-checkbox"))}
        checked={aiWarnignDontShowAgain}
        onChange={(e) => setAiWarningDontShowAgain(e.target.checked)}
        onAccept={recognizeByAIWithWarningHanlder}
      />
    </WizardLayout>
  );
};

export default RecognitionResults;
