const ToastsService = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  open(component, props = {}) {
    document.dispatchEvent(new CustomEvent("open-toast", {detail: {component, props}}));
  },
  closeToasts() {
    document.dispatchEvent(new CustomEvent("close-toast"));
  },
};

export default ToastsService;
