import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchWithToken} from "../utils";

const tokenFetch = fetchWithToken(fetch);

export const createAsyncThunkAction = (name, url, requestType, argType, options) => {
  const customFetch = options?.isNeedAuth ? tokenFetch : fetch;

  return createAsyncThunk(name, async (arg, thunkAPI) => {
    // let queries = null; // в будущем добавится еще использование queries
    let param = "";
    let data = null;

    if (argType) {
      //queries = argType === "queries" ? arg : null; // в будущем добавится еще использование queries
      param = argType === "param" ? arg : "";
      data = argType === "data" ? arg : null;
    } else {
      //queries = arg?.queries || null; // в будущем добавится еще использование queries
      param = arg?.param || "";
      data = arg?.data || null;
    }

    try {
      let response = null;
      switch (requestType) {
        case "GET":
          response = await customFetch(url + param);
          break;
        case "POST":
          response = await customFetch(url + param, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({...data}),
          });
          break;
        case "POST/formdata":
          response = await customFetch(url + param, {
            method: "POST",
            body: data,
          });
          break;
        case "PATCH":
          response = await customFetch(url + param, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({...data}),
          });
          break;
        case "PATCH/formdata":
          response = await customFetch(url + param, {
            method: "PATCH",
            body: data,
          });
          break;
        case "DELETE":
          response = await customFetch(url + param, {
            method: "DELETE",
          });
          break;
        default:
          break;
      }

      //object is 'null' or undefined
      const responseData = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return requestType === "DELETE"
          ? Promise.reject("Something is going wrong. Try later")
          : thunkAPI.rejectWithValue(responseData);
      }

      if (options?.cb) {
        options.cb(responseData);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  });
};
