//изменяет стандартное отображение компонентов во всем проекте (через js)
//через селектор выбираем нужные элементы
//затем добавляем класс к этим всем элементам
//глобальный класс необходимо хранить в custom.scss
export default function themeTool(selector, className) {
  const elements = document.querySelectorAll(selector);
  for (let element of elements) {
    element.classList.add(className);
  }
}
