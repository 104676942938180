import React from "react";

export const DownArrow = ({fill}) => {
  return (<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.58002 0.22L0.400024 1.4L7.00002 8L13.6 1.4L12.42 0.219999L7.00002 5.64L1.58002 0.22Z" fill={fill}/>
  </svg>)
}

export const UpArrow = ({fill}) => {
  return (<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.42 7.78L13.6 6.6L6.99998 -2.88495e-07L0.399975 6.6L1.57998 7.78L6.99998 2.36L12.42 7.78Z" fill={fill}/>
  </svg>)
}