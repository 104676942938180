import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import {MainColors} from "../../constants/CommonProperties";

const AiWarningModal = ({
  isOpen,
  toogle,
  headerText,
  bodyText,
  checkboxText,
  checked,
  onAccept: accept,
  onChange: change,
}) => {
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toogle}>{headerText}</ModalHeader>
      <ModalBody className="position-relative">
        <p style={{color: "#404040"}}>{bodyText}</p>
        <FormGroup className="d-flex justify-content-end" check>
          <Label check style={{color: MainColors.greyPalette.grey6}}>
            <Input onChange={change} checked={checked} type="checkbox" /> {checkboxText}
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter style={{borderTop: "none"}}>
        <Button size="sm" color="secondary" onClick={toogle}>
          {"CANCEL"}
        </Button>{" "}
        <Button size="sm" color="primary" onClick={accept}>
          {"OK"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AiWarningModal;
