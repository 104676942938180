import {createSlice} from "@reduxjs/toolkit";
import {registerUser, loginUser, googleAuth, logout, getUserData} from "./actions";

export const authV2 = createSlice({
  name: "authV2",
  initialState: {
    isPending: false,
    errorText: null,
    user: null,
    isLoggedIn: false,
  },
  reducers: {
    setUserTokenExpired: (state) => {
      localStorage.removeItem("token");
      state.user = null;
      state.isLoggedIn = false;
      state.isPending = false;
      state.errorText = "token is expired";
    },
    loginUserByLocalStorage: (state) => {
      //redundant if statement
      if (localStorage.getItem("token")) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.isPending = true;
    },
    [registerUser.fulfilled]: (state) => {
      state.isPending = false;
      state.isLoggedIn = true;
      state.errorText = null;
    },
    [registerUser.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
    [googleAuth.pending]: (state) => {
      state.isPending = true;
    },
    [googleAuth.fulfilled]: (state) => {
      state.isPending = false;
      state.isLoggedIn = true;
      state.errorText = null;
    },
    [googleAuth.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
    [loginUser.fulfilled]: (state) => {
      state.isPending = false;
      state.isLoggedIn = true;
      state.errorText = null;
    },
    [loginUser.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
    [logout.pending]: (state) => {
      state.isPending = true;
    },
    [logout.fulfilled]: (state) => {
      state.isPending = false;
      state.isLoggedIn = false;
      state.user = null;
      state.errorText = null;
    },
    [logout.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
    [getUserData.pending]: (state) => {
      state.isPending = true;
    },
    [getUserData.fulfilled]: (state, action) => {
      state.isPending = false;
      state.user = action.payload;
      state.errorText = null;
    },
    [getUserData.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
  },
});

export const {setUserTokenExpired, loginUserByLocalStorage} = authV2.actions;

export default authV2.reducer;
