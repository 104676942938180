import React, {Component} from "react";
import {Layer} from "react-konva";
import Section from "../Section/Section";

const minAreaWidth = 10;
const minAreaHeight = 10;

class Selector extends Component {
  constructor(props) {
    super(props);
    this.stage = props.stageRef.current;
  }

  componentWillUnmount() {
    this.stage.removeEventListener("mousedown", this.onMouseDownHandler);
    this.stage.removeEventListener("mouseup", this.onMouseUpHandler);
    this.stage.removeEventListener("mousemove", this.changeSectionSize);
  }

  componentDidMount() {
    this.stage.addEventListener("mousedown", this.onMouseDownHandler);
    this.stage.addEventListener("mouseup", this.onMouseUpHandler);
  }

  _getCoords(event) {
    const stageScale = this.props.stageRef.current.scale();
    const xStageOffsetByDragImage = this.props.stageRef.current._lastPos?.x || 0;
    const yStageOffsetByDragImage = this.props.stageRef.current._lastPos?.y || 0;

    return {
      x: (event.layerX - xStageOffsetByDragImage) / stageScale.x,
      y: (event.layerY - yStageOffsetByDragImage) / stageScale.y,
    };
  }

  changeSectionSize = (event) => {
    /*----------------need to ban drawing new area on a not image field-------------------*/
    const stageScale = this.props.stageRef.current.scale();
    const xStageOffsetByDragImage = this.props.stageRef.current._lastPos?.x || 0;
    const yStageOffsetByDragImage = this.props.stageRef.current._lastPos?.y || 0;
    const selectorX = event.layerX - xStageOffsetByDragImage;
    const selectorY = event.layerY - yStageOffsetByDragImage;
    const xRightEdgeofImage =
      this.props.imageXOffset +
      this.props.templateImageRef.current.attrs.image.width * stageScale.x;
    const yRighEdgeOfImage =
      this.props.imageYOffset +
      this.props.templateImageRef.current.attrs.image.height * stageScale.y;

    if (selectorX <= this.props.imageXOffset || selectorY <= this.props.imageYOffset)
      return 0; //need to ban drawing new area on a not image field on a left canvas side
    if (selectorX >= xRightEdgeofImage || selectorY >= yRighEdgeOfImage) {
      return 0; //need to ban drawing new area on a not image field on a right canvas side
    }

    this.props.setSelectedArea((selectedArea) => ({
      ...selectedArea,
      endPosition: this._getCoords(event),
    }));
  };

  onMouseDownHandler = (event) => {
    /*----------------need to ban drawing new area on a not image field-------------------*/
    const stageScale = this.props.stageRef.current.scale();
    const xStageOffsetByDragImage = this.props.stageRef.current._lastPos?.x || 0;
    const yStageOffsetByDragImage = this.props.stageRef.current._lastPos?.y || 0;
    const selectorX = event.layerX - xStageOffsetByDragImage;
    const selectorY = event.layerY - yStageOffsetByDragImage;
    const xRightEdgeofImage =
      this.props.imageXOffset +
      this.props.templateImageRef.current.attrs.image.width * stageScale.x;
    const yRighEdgeOfImage =
      this.props.imageYOffset +
      this.props.templateImageRef.current.attrs.image.height * stageScale.y;

    if (selectorX <= this.props.imageXOffset || selectorY <= this.props.imageYOffset)
      return 0; //need to ban drawing new area on a not image field on a left canvas side
    if (selectorX >= xRightEdgeofImage || selectorY >= yRighEdgeOfImage) {
      return 0; //need to ban drawing new area on a not image field on a right canvas side
    }
    /*-------------------------------------------------------------------------------------*/

    const startPosition = this._getCoords(event);

    this.props.setSelectedArea((selectedArea) => ({...selectedArea, startPosition}));

    this.stage.addEventListener("mousemove", this.changeSectionSize);
  };

  onMouseUpHandler = (e) => {
    this.stage.removeEventListener("mousemove", this.changeSectionSize);

    if (!this.props.selectedArea.startPosition || !this.props.selectedArea.endPosition)
      return; // if there is not area properties then return so don't show modal window
    if (
      this.getSection().width < minAreaWidth ||
      this.getSection().height < minAreaHeight
    ) {
      this.props.setSelectedArea({
        startPosition: null,
        endPosition: null,
      });
      return; // if min width or min height are too small then return so clear area and don't show modal window
    }

    this.props.onDraw && this.props.onDraw(this.getSection());
  };

  getSection = () => {
    let width = null;
    let height = null;

    if (this.props.selectedArea.endPosition?.x) {
      width =
        this.props.selectedArea.endPosition.x > this.props.selectedArea.startPosition.x
          ? this.props.selectedArea.endPosition.x -
            this.props.selectedArea.startPosition.x
          : this.props.selectedArea.startPosition.x -
            this.props.selectedArea.endPosition.x;
    }
    if (this.props.selectedArea.endPosition?.y) {
      height =
        this.props.selectedArea.endPosition.y > this.props.selectedArea.startPosition.y
          ? this.props.selectedArea.endPosition.y -
            this.props.selectedArea.startPosition.y
          : this.props.selectedArea.startPosition.y -
            this.props.selectedArea.endPosition.y;
    }
  
    //redundant variable
    const result = {
      x:
        this.props.selectedArea.endPosition?.x > this.props.selectedArea.startPosition?.x
          ? this.props.selectedArea.startPosition?.x
          : this.props.selectedArea.endPosition?.x,
      y:
        this.props.selectedArea.endPosition?.y > this.props.selectedArea.startPosition?.y
          ? this.props.selectedArea.startPosition?.y
          : this.props.selectedArea.endPosition?.y,
      width,
      height,
    };

    return result;
  };

  render() {
    return (
      <Layer>
        {this.props.selectedArea.startPosition && <Section area={this.getSection()} />}
      </Layer>
    );
  }
}

export default Selector;
