import React, {useState} from "react";
import Langs from "../../../constants/Lang";
import {Toast, ToastBody, Form, FormGroup, Label, Input, Button, Row} from "reactstrap";
import {PlusIcon} from "../../Icons";
import {CloseIcon} from "../../Icons";
import {useFormik} from "formik";
import "./SaveAreaForm.scss";
import {capitalize, capitalizeFirstLetter} from "../../../utils";
import {useTranslation} from "react-i18next";
import RefWrapper from "../../RefWrapper";
import {getProductionLanguage} from "../../../utils";

const SaveAreaForm = React.forwardRef(
  ({area, areas, hiddenCancel, showCreateAreaForm, onSave, onCancel}, ref) => {
    const {t} = useTranslation();

    const [warning, setWarning] = useState(false);

    const formik = useFormik({
      initialValues: {
        name: area?.name || "",
        lang: getProductionLanguage() === "ru" ? "rus" : "eng",
      },
      onSubmit: (values) => {
        if (warning) return;
        onSave({...values, name: values.name.trim()});
      },
    });

    const handleChange = (e) => {
      const value = e.target.value.trim();
      setWarning(false);
      for (let area of areas) {
        if (area.name === value) {
          setWarning(true);
        }
      }
      formik.handleChange(e);
    };

    return (
      <Toast id="save-section-form" className={"save-area-form"}>
        <div id="toast-header" className="pr-4 pl-4 pt-2 pb-2 save-area-form__header">
          {showCreateAreaForm.type === "create"
            ? capitalizeFirstLetter(t("save-area.add-mark"))
            : capitalizeFirstLetter(t("save-area.edit-mark"))}
          <div style={{cursor: "pointer"}}>
            {!hiddenCancel ? (
              <CloseIcon onClick={onCancel} height="14" width="14" fill="white" />
            ) : null}
          </div>
        </div>
        <ToastBody className="pr-4 pl-4 pt-2 pb-3 save-area-form__body">
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-2">
              <Label for="name">{capitalize(t("save-area.mark-name"))}</Label>
              <Input
                style={warning ? {borderColor: "#dc3545"} : null}
                required
                type="text"
                name="name"
                id="name"
                maxLength="50"
                autoFocus
                autoComplete="off"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </FormGroup>
            {warning && (
              <div style={{marginTop: "-5px"}} className="text-danger">
                {capitalizeFirstLetter(t("save-area.field-exist"))}
              </div>
            )}
            {getProductionLanguage() === "ru" && (
              <FormGroup style={{marginTop: "-2.5px"}}>
                <Label for="lang">{capitalizeFirstLetter(t("save-area.language"))}</Label>
                <Input
                  className="save-area-form__input"
                  required
                  type="select"
                  name="lang"
                  id="lang"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.lang}
                >
                  {Langs.map((lang) => (
                    <option key={lang.id} value={lang.id}>
                      {lang.desc}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
            <Row noGutters className="justify-content-end">
              {!hiddenCancel ? (
                <Button className="mr-2" size="sm" onClick={onCancel} color="link">
                  {t("modal.cancel").toLocaleUpperCase()}
                </Button>
              ) : null}
              <RefWrapper ref={ref}>
                <Button size="sm" type="submit" color="primary">
                  <PlusIcon className="mr-2 mb-1" width="12" height="12" fill="white" />
                  {t("modal.add").toLocaleUpperCase()}
                </Button>
              </RefWrapper>
            </Row>
          </Form>
        </ToastBody>
      </Toast>
    );
  }
);

export default SaveAreaForm;
