import React from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {SelectIcon, MoveIcon, EditIcon, ResizeIcon} from "../Icons";
import {MainColors} from "../../constants/CommonProperties";
import {useTranslation} from "react-i18next";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import "./HelpModal.scss";

const HelpModal = ({close}) => {
  const {t} = useTranslation();

  return (
    <Modal isOpen centered className={`help-modal`}>
      <ModalHeader toggle={() => close()} className="help-modal__header">
        {capitalize(t("help-modal.title"))}
      </ModalHeader>
      <ModalBody className="help-modal__body">
        <div className="text-justify">
          <SelectIcon
            className="mb-1 mr-3"
            width="22px"
            height="22px"
            fill={MainColors.greyPalette.typeBlack}
          />
          <span className="subject-bold">
            {capitalizeFirstLetter(t("help-modal.mark-area"))}
          </span>
          <p className="help-modal__instruction body-regular">
            {t("help-modal.mark-area-instruction")}
          </p>
        </div>
        <div className="text-justify">
          <EditIcon
            className="mb-1 mr-3"
            width="22px"
            height="22px"
            fill={MainColors.greyPalette.typeBlack}
          />
          <span className="subject-bold">{t("help-modal.selection")}</span>
          <p className="help-modal__instruction body-regular">
            {t("help-modal.selection-instruction")}
          </p>
        </div>
        <div className="text-justify">
          <ResizeIcon
            className="mb-1 mr-3"
            width="22px"
            height="22px"
            fill={MainColors.greyPalette.typeBlack}
          />
          <span className="subject-bold">
            {capitalizeFirstLetter(t("help-modal.resize"))}
          </span>
          <p className="help-modal__instruction body-regular">
            {t("help-modal.resize-instructions")}
          </p>
        </div>
        <div className="text-justify">
          <MoveIcon
            className="mb-1 mr-3"
            width="22px"
            height="22px"
            fill={MainColors.greyPalette.typeBlack}
          />
          <span className="subject-bold">
            {capitalizeFirstLetter(t("help-modal.move"))}
          </span>
          <p className="help-modal__instruction body-regular">
            {t("help-modal.move-instructions")}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default HelpModal;
