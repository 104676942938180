import React, {useState, useRef, useEffect, useCallback} from "react";
import {ListGroup, ListGroupItem} from "reactstrap";
import {CloseIcon, PlusIcon, PencilIcon} from "../Icons";
import "./CustomDropDown.scss";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import {useTranslation} from "react-i18next";
import {MainColors} from "../../constants/CommonProperties";
import debounce from "../../utils/debounce";

const CustomDropDown = React.forwardRef(
  (
    {
      component,
      style,
      data,
      onGetGroups: getGroups,
      onSetGroup: setGroup,
      onCreateNewGroup: createNewGroup,
      onDeleteGroup: deleteGroup,
      onEditGroup: editGroup,
      props,
    },
    ref
  ) => {
    const {t} = useTranslation();
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const [addGroupField, setAddGroupField] = useState("");
    const [isAddingGroupActive, setIsAddingGroupActive] = useState(false);
    const [groupNames, setGroupNames] = useState([]);
    const [initialGroupName, setInitialGroupNames] = useState([]);
    const [groupErrorsMap, setGroupErrorsMap] = useState(new Map());
    const dropDownRef = useRef();
    const inputsRefs = [];

    useOnClickOutside(dropDownRef, () => {
      for (let error of groupErrorsMap) {
        if (error) {
          return;
        }
      }
      getGroups();

      setIsShowDropDown(false);
      setIsAddingGroupActive(false);
    });

    const chooseGroupHandler = (group, index, action) => {
      for (let error of groupErrorsMap) {
        if (error) {
          if (!error["creationOfNewGroup"]) {
            setIsAddingGroupActive(false);
          }
          return;
        }
      }

      if (action === "create-new-group") {
        setGroup(group);
        setAddGroupField("");
      } else {
        //edit group
        const editedGroupName = groupNames[index];
        setGroup({...group, name: editedGroupName});
      }

      setIsShowDropDown(false);
      setIsAddingGroupActive(false);
    };

    const createNewGroupHandler = (groupName) => {
      createNewGroup(groupName)
        .then((data) => {
          if (data) {
            if (data.error) {
              if (data.error.message) {
                setGroupErrorsMap(
                  (map) => new Map(map.set("creationOfNewGroup", t(data.error.message)))
                );
              } else {
                alert(t("dropdown.error-text"));
              }
            } else {
              setGroupErrorsMap((map) => {
                map.delete("creationOfNewGroup");
                return new Map(map);
              });
              chooseGroupHandler(data.payload, null, "create-new-group");
            }
          }
        })
        .catch((err) => alert(t(err)));
    };

    const clickEditPencilHandler = (index) => {
      inputsRefs[index].focus();
    };

    const debouncedEditGroup = useCallback(
      debounce((groupId, name, isInitName) => {
        editGroup(groupId, name, isInitName)
          .then((data) => {
            if (data.error) {
              if (data.error.message) {
                setGroupErrorsMap(
                  (map) => new Map(map.set(groupId, t(data.error.message)))
                );
              } else {
                alert(t("template-editor.error-text"));
              }
            } else {
              setGroupErrorsMap((map) => {
                map.delete(groupId);
                return new Map(map);
              });
            }
          })
          .catch(() => alert(t("template-editor.error-text")));
      }, 500),
      []
    );

    const editGroupHandler = (e, index, groupId) => {
      const name = e.target.value;

      setGroupNames(groupNames.map((item, i) => (i === index ? name : item)));

      const isInitName = initialGroupName[index] === name;

      debouncedEditGroup(groupId, name, isInitName);
    };

    useEffect(() => {
      setGroupNames(data.map((item) => item.name));
      setInitialGroupNames(data.map((item) => item.name));
    }, [data]);

    return (
      <div ref={ref} style={style} {...props}>
        <div
          className="position-relative cursor-pointer"
          onClick={() => setIsShowDropDown(true)}
        >
          {component}
        </div>
        {isShowDropDown && (
          /*----------------------All existing groups-------------------*/
          <div ref={dropDownRef}>
            <ListGroup className={"custom-dropdown__list-group"}>
              {data.length ? (
                <>
                  <ListGroupItem
                    className="p-2 border-0 cursor-pointer group-pop-up-item d-flex align-items-center justify-content-center"
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    <span onClick={() => chooseGroupHandler(null)}>
                      {capitalize(t("dropdown.remove-group"))}
                    </span>
                  </ListGroupItem>
                  {data.map((item, index) => (
                    <>
                      <ListGroupItem
                        key={item.name}
                        className="p-2 border-0 custom-dropdown__list-group-item"
                      >
                        <input
                          className={"custom-dropdown__input"}
                          maxLength="30"
                          spellcheck="false"
                          onClick={() => chooseGroupHandler(item, index)}
                          ref={(ref) => (inputsRefs[index] = ref)}
                          value={groupNames[index]}
                          onChange={(e) => editGroupHandler(e, index, item.id)}
                          placeholder={capitalizeFirstLetter(
                            t("dropdown.input-group-name")
                          )}
                        />
                        <div className={"custom-dropdown__button-container"}>
                          <PencilIcon
                            //Refactor using svg icons
                            className="mr-2"
                            width="12px"
                            height="12px"
                            fill={MainColors.greyPalette.grey6}
                            onClick={() => clickEditPencilHandler(index)}
                          />
                          <CloseIcon
                            width="10px"
                            height="10px"
                            fill={MainColors.greyPalette.grey6}
                            onClick={(e) => deleteGroup(e, item)}
                          />
                        </div>
                      </ListGroupItem>
                      {groupErrorsMap.has(item.id) && (
                        <ListGroupItem
                          key={item.name + "1"}
                          className="pl-2 pr-2 pb-0 pt-0 border-0 custom-dropdown__error-field"
                        >
                          {<small>{groupErrorsMap.get(item.id)}</small>}
                        </ListGroupItem>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <div className="pb-3 pt-4 pr-4 pl-4 custom-dropdown__no-groups-placeholder">
                  {capitalizeFirstLetter(t("dropdown.no-group"))}
                </div>
              )}
              {/*------adding new group by  new group input field------*/}
              {isAddingGroupActive && (
                <>
                  <ListGroupItem className="p-1 border-0">
                    <div className="add-new-group-wrapper">
                      <input
                        className="add-new-group-wrapper__input p-1"
                        type="text"
                        maxLength="30"
                        autoFocus
                        spellcheck="false"
                        value={addGroupField}
                        onChange={(e) => setAddGroupField(e.target.value)}
                        placeholder={capitalizeFirstLetter(
                          t("dropdown.input-group-name")
                        )}
                      />
                      <div
                        className="add-new-group-wrapper__icon-container"
                        onClick={() => createNewGroupHandler(addGroupField)}
                      >
                        <PlusIcon
                          width="14px"
                          height="14px"
                          fill={MainColors.purplePrimary.purplePrimary}
                          className={"plus-icon"}
                        />
                      </div>
                    </div>
                  </ListGroupItem>
                  {groupErrorsMap.has("creationOfNewGroup") && (
                    <ListGroupItem className="pl-2 pr-2 pb-0 pt-0 border-0 custom-dropdown__error-field">
                      {<small>{groupErrorsMap.get("creationOfNewGroup")}</small>}
                    </ListGroupItem>
                  )}
                </>
              )}
              {/*----------------------+ NEW field-------------------*/}
              <ListGroupItem
                onClick={() => {
                  if (!isAddingGroupActive) {
                    setIsAddingGroupActive(true);
                  }
                }}
                className={
                  "p-2 border-0 custom-dropdown__list-group-item new-field-container " +
                  (isAddingGroupActive ? "custom-dropdown__list-group-item_blocked" : "")
                }
              >
                <PlusIcon
                  width="12px"
                  height="12px"
                  fill={
                    isAddingGroupActive
                      ? MainColors.greyPalette.grey5
                      : MainColors.purplePrimary.purplePrimary
                  }
                  className="mr-2"
                />
                <span
                  className={
                    "new-field-container__field " +
                    (isAddingGroupActive ? "new-field-container__field_blocked" : "")
                  }
                >
                  {t("dropdown.new").toLocaleUpperCase()}
                </span>
              </ListGroupItem>
            </ListGroup>
          </div>
        )}
      </div>
    );
  }
);

export default CustomDropDown;
