import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Alert,
} from "reactstrap";
import {ReactComponent as DocIcon} from "../../../../assets/DocIcon.svg";
import {ReactComponent as GoogleIcon} from "../../../../assets/Google.svg";
import {useTranslation} from "react-i18next";
import {capitalize, capitalizeFirstLetter} from "../../../../utils";

const NonDesktopSignInComponent = ({
  alertText,
  isAlertShown,
  formik,
  apiErrors,
  privacyUrl,
  onGoogleAuth: googleAuthHandler,
  onGoToSignUp: goToSignUp,
  onGoToReset: goToReset,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <Alert isOpen={isAlertShown} className={"success-alert"} color="success">
        {alertText}
      </Alert>
      <Row noGutters className={"sign-form-non-desktop__row"}>
        <Form onSubmit={formik.handleSubmit} className={"sign-form-non-desktop__form"}>
          <div>
            <div className="p-3 sign-form-non-desktop__header">
              <DocIcon className="sign-form-non-desktop__logo" />
              {t("sign-in.sign-in").toLocaleUpperCase()}
            </div>
            <div className="p-3 sign-form-non-desktop__body">
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  invalid={
                    !!(
                      formik.touched.email &&
                      (formik.errors.email ||
                        (apiErrors.email && Boolean(apiErrors.email[0])))
                    )
                  }
                  valid={
                    formik.touched.email && (!formik.errors.email || !apiErrors.email)
                  }
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-in.email-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormFeedback>
                  {t(
                    formik.touched.email &&
                      (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{marginTop: "-10px"}}>
                <Label for="password">{capitalize(t("sign-in.password"))}</Label>
                <Input
                  invalid={
                    !!(
                      formik.touched.password &&
                      (formik.errors.password ||
                        (apiErrors.password && Boolean(apiErrors.password[0])))
                    )
                  }
                  valid={
                    formik.touched.password &&
                    (!formik.errors.password || !apiErrors.password)
                  }
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <FormFeedback>
                  {t(
                    formik.touched.password &&
                      (formik.errors.password ||
                        (apiErrors.password && apiErrors.password[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <div className="mt-4 text-danger text-center">
                {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
              </div>
            </div>
          </div>
          <div className="sign-form-non-desktop__footer">
            <div className="d-flex justify-content-between">
              <div style={{width: "40%"}}>
                <Button
                  size="sm"
                  style={{width: "100%"}}
                  color="link"
                  className="sign-form-non-desktop__button"
                  onClick={goToSignUp}
                >
                  {t("sign-up.sign-up").toLocaleUpperCase()}
                </Button>
              </div>
              <div style={{width: "60%"}} className="d-flex justify-content-end">
                <Button
                  style={{width: "30%"}}
                  size="sm"
                  color="primary"
                  className="sign-form-non-desktop__button mr-2"
                  onClick={googleAuthHandler}
                >
                  <GoogleIcon />
                </Button>
                <Button
                  style={{width: "70%"}}
                  size="sm"
                  type="submit"
                  color="primary"
                  className="sign-form-non-desktop__button"
                >
                  {t("sign-in.sign-in").toLocaleUpperCase()}
                </Button>
              </div>
            </div>
            <div className="text-center pt-2">
              <Button
                size="sm"
                color="link"
                className="mr-3 sign-form-non-desktop__button"
                onClick={goToReset}
              >
                {t("sign-in.forgot-password").toLocaleUpperCase()}
              </Button>
              <div className={"sign-form-non-desktop__privacy-text"}>
                {t("sign-in.privacy-text")}
                <a target={"_blank"} href={privacyUrl}>
                  {t("sign-in.privacy-link")}
                </a>
              </div>
            </div>
          </div>
        </Form>
      </Row>
    </>
  );
};

export default NonDesktopSignInComponent;
