import React, {useState, useEffect, useRef} from "react";
import {Stage, Layer, Image} from "react-konva";
import {connect, useDispatch, useSelector} from "react-redux";
import AreasList from "./AreaList/AreaList";
import Selector from "./Selector/Selector";
import SaveAreaForm from "./SaveAreaForm/SaveAreaForm";
import ControlPanel from "./ControlPanel/ControlPanel";
import {ControlPanelTools} from "../../constants/EditorConsts";
import {
  createNewArea,
  removeArea,
  editArea,
  moveArea,
  resizeArea,
  setDocumentName,
  highlightArea,
  clearAllHighlightedAreas,
  setTemplateGroup,
  createNewDocument,
  returnOneActionBack,
  returnOneActionForward,
  setIsTemplateWereEdit,
} from "../../redux/feature/docTemplateEditorV2";
import {deleteTemplate} from "../../redux/feature/docTemplateEditorV2/actions";
import {
  getGroups,
  createNewGroup,
  deleteGroup,
  editGroup,
} from "../../redux/feature/groupsV2/actions";
import {logout} from "../../redux/feature/authV2/actions";
import {setFileToRecognize} from "../../redux/feature/recognitionV2";
import {recognize} from "../../redux/feature/recognitionV2/actions";
import SizeTumbler from "./SizeTumbler/SizeTumbler";
import "./TemplateEditor.scss";
import {Row, Col} from "reactstrap";
import EditBox from "./EditBox/EditBox";
import Help from "./Help/Help";
import {useHistory, useLocation} from "react-router-dom";
import {MainColors} from "../../constants/CommonProperties";
import {
  useOnClickOutsideDocumentAreas,
  useOnClickOutsideCanvasAreas,
} from "../../hooks/useOnClickOutside";
import TemplateEditorHeader from "../DefaultLayout/TemplateEditorHeader/TemplateEditorHeader";
import WarningModal from "../WarningModal/WarningModal";
import HelpModal from "../HelpModal/HelpModal";
import ConfirmModal from "../ConfrimModal/ConfirmModal";
import ToastComponent from "../Toast";
import ActionsManagePanel from "./ActionsManagePanel";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../utils";
import {processPdfFile} from "../../utils";
import {processImageFile} from "../../utils";
import usePrevious from "../../hooks/usePrevious";
import WizardLayout from "../WizardLayout";
import {useNode} from "../../hooks/useNode";
import ModalService from "../../modules/modals/services/ModalService";
import ToastsService from "../../modules/toasts/services/ToastsService";

const DEFAULT_SCALE = 0.9;
const reidrectTimeout = 1500;

const initialState = {
  startPosition: null,
  endPosition: null,
};

const TemplateEditor = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const backlog = useSelector((state) => state.docTemplateEditorV2.dataBackLog);
  const canceledActions = useSelector(
    (state) => state.docTemplateEditorV2.canceledActions
  );
  const currTool = useSelector((state) => state.docTemplateEditorV2.currTool);
  const areas = useSelector((state) => state.docTemplateEditorV2.currentData.areas);
  const document = useSelector((state) => state.docTemplateEditorV2.currentData.document);
  const initialData = useSelector((state) => state.docTemplateEditorV2.initialData);
  const highlightedAreas = useSelector(
    (state) => state.docTemplateEditorV2.highlightedAreas
  );
  const groups = useSelector((state) => state.groupsV2.results);
  const templateUuid = useSelector((state) => state.docTemplateEditorV2.tplId);

  const stageRef = useRef();
  const templateImageRef = useRef();
  const canvasWrapperRef = useRef();

  //--------------wizard data--------------------------------------------------
  const [wizardFirstTargetNode, wizardFirstTargetRef] = useNode();
  const [wizardThirdTargetNode, wizardThirdTargetRef] = useNode();
  const [wizardFourthTargetNode, wizardFourthTargetRef] = useNode();
  const [wizardFifthTargetNode, wizardFifthTargetRef] = useNode();

  const [wizardThirdPointerNode, wizardThirdPointerRef] = useNode();

  const [wizardStep, setWizardStep] = useState(0);
  const [wizardTarget, setWizardTarget] = useState(null);
  const [wizardPointer, setWizardPointer] = useState(null);
  const [wizardRadius, setWizardRadius] = useState("0px");
  const [wizardHelperText, setWizardHelperText] = useState("");
  const [wizardXHelperText, setWizardXHelperText] = useState(0);
  const [wizardYHelperText, setWizardYHelperText] = useState(0);
  const [wizardNextStepFunction, setWizardNextStepFunction] = useState(null);
  //---------------------------------------------------------------------------

  const [showCreateAreaForm, setShowCreateAreaForm] = useState(null); // modal window for saving new area
  const [area, setArea] = useState(null); //set new area properties
  const [scale, setScale] = useState(DEFAULT_SCALE); //set scale of canvas
  const [initialScale, setInitialScale] = useState(DEFAULT_SCALE); //set initial scale of image to use them in reset
  const [selectedAreaId, setSelectedAreaId] = useState(null); //for selecting area and then drag, edit, move and so on
  const [selectedArea, setSelectedArea] = useState(initialState); //for selector component
  const [autosaveTime, setAutosaveTime] = useState("");
  const [templateNameError, setTemplateNameError] = useState("");

  const [canvasHeight, setcanvasHeight] = useState(0);
  const [canvasWidth, setcanvasWidth] = useState(0);

  const [isEventFromEditBox, setIsEventFromEditBox] = useState(false); //check whether event from editBox or canvas+control panel

  //for success/fail toasts and spinner for save button
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingAndRecognize, setIsSavingAndRecognize] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);

  useOnClickOutsideDocumentAreas("data-area", () => {
    dispatch(clearAllHighlightedAreas());
    setSelectedAreaId(null);
  });
  useOnClickOutsideCanvasAreas(stageRef.current, () => {
    dispatch(clearAllHighlightedAreas());
    setSelectedAreaId(null);
  });

  const imageXOffset = (canvasWidth - document.width * scale) / 2; // perfect centered iamge on canvas
  const imageYOffset = (canvasHeight - document.height * scale) / 2; // perfect centered iamge on canvas

  const checkCanvasSize = () => {
    let computedStyle = getComputedStyle(canvasWrapperRef.current);

    let paddingX =
      parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    let borderX =
      parseFloat(computedStyle.borderLeftWidth) +
      parseFloat(computedStyle.borderRightWidth);

    let paddingY =
      parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
    let borderY =
      parseFloat(computedStyle.borderTopWidth) +
      parseFloat(computedStyle.borderBottomWidth);

    let canvasWidth = canvasWrapperRef.current.offsetWidth - paddingX - borderX;
    let canvasHeight = canvasWrapperRef.current.offsetHeight - paddingY - borderY;

    setcanvasWidth(canvasWidth);
    setcanvasHeight(canvasHeight);
  }; //for adaptive canvas size

  const convertAreaCoordinates = (coordinateType = "absolute", area) => {
    //absolute means that area coordinates relative to document
    //otherwise relative. it means that area coordinates relative to canvas
    //absolute coordinates needs backend and relative needs frontend

    return coordinateType === "absolute"
      ? {
          ...area,
          x: area.x - imageXOffset / scale,
          y: area.y - imageYOffset / scale,
        }
      : {
          ...area,
          x: area.x + imageXOffset / scale,
          y: area.y + imageYOffset / scale,
        };
  };

  const selectAreaHandler = (id) => {
    setSelectedAreaId(id);
  };

  const checkDeselectAreaHandler = (e) => {
    //that means click on any field except area turn off area select

    const clickOnEmpty = e.target.attrs.image || e.target.attrs.container;
    if (clickOnEmpty) {
      setSelectedAreaId(null);
    }
  };

  const drawAreaBySelectorHandler = (area) => {
    //draw new area by selector component and save it to local state
    const absoluteArea = convertAreaCoordinates("absolute", area);
    //show a form for saving new area
    setWizardStep(2);
    setShowCreateAreaForm({type: "create", status: true});
    setArea(absoluteArea);
  };

  const saveAreaHandler = (data) => {
    //save new area after click "add" button on the "add a mark field" (createAreaForm)
    // isEventFromEditBox need to distinguish what field user click - buttons on a EditBoxField or
    // buttons on ControlPanel
    setWizardStep(1);
    if (isEventFromEditBox) {
      dispatch(
        editArea({
          data,
          area,
        })
      );
      setIsEventFromEditBox(false);
      setShowCreateAreaForm(null);
      setArea(null);
      return;
    }
    switch (currTool) {
      case ControlPanelTools.SELECTING:
        dispatch(createNewArea({...data, ...area}));
        break;
      case ControlPanelTools.EDITING:
        dispatch(
          editArea({
            data,
            area,
          })
        );
        break;
      default:
        break;
    }
    dispatch(setIsTemplateWereEdit(true));
    setShowCreateAreaForm(null);
    setArea(null);
    setSelectedArea(initialState); //clear selector after click button "add" or "cancel" in the createAreaForm
  };

  const cancelSavingAreaHandler = () => {
    setShowCreateAreaForm(null);
    setArea(null);
    setIsEventFromEditBox(false);
    setSelectedArea(initialState); //clear selector after click button "add" or "cancel" in the createAreaForm
  };

  const editAreaOnClickHandler = (area) => {
    if (currTool === ControlPanelTools.EDITING) {
      setArea(area);
      setShowCreateAreaForm({type: "edit", status: true});
      dispatch(setIsTemplateWereEdit(true));
    }
  };

  const deleteByCloseIconHandler = (area) => {
    dispatch(removeArea(area));
    dispatch(setIsTemplateWereEdit(true));
  };

  const editBoxAreaOnClickHandler = (tool) => (area) => {
    dispatch(setIsTemplateWereEdit(true));
    switch (tool) {
      case "remove":
        dispatch(removeArea(area));
        break;
      case "edit":
        setIsEventFromEditBox(true);
        setShowCreateAreaForm({type: "edit", status: true});
        setArea({...area});
        break;
      default:
        break;
    }
  };

  const dragAreaHandler = (attrs, cid) => {
    const area = {x: attrs.x, y: attrs.y};
    dispatch(moveArea({...convertAreaCoordinates("absolute", area), cid}));
    dispatch(setIsTemplateWereEdit(true));
  };

  const transfromAreaHanlder = (areaparams, cid) => {
    const area = convertAreaCoordinates("absolute", areaparams);
    dispatch(resizeArea({...area, cid}));
    dispatch(setIsTemplateWereEdit(true));
  };

  const hightLightAreaHandler = (areaId, eventType) => {
    dispatch(highlightArea({areaId, eventType}));
  };

  const changeScaleHandler = (val) => {
    setScale(val);
  };

  const setDocumentNameHandler = (name) => {
    dispatch(setDocumentName(name));
    dispatch(setIsTemplateWereEdit(true));
  };

  const saveDocTemplateHandler = async (pushUrl) => {
    if (templateNameError) {
      return 0;
    }
    try {
      setIsSaving(true);
      setIsSaveError(false);
      const data = await props.onSave({document, areas}, templateUuid);
      if (data.error) {
        throw data;
      }

      const isWizardActive = !localStorage.getItem("parsio.skipWizardStatus");
      setTimeout(() => {
        isWizardActive ? history.push("/recognition/results") : history.push(pushUrl);
      }, reidrectTimeout);
    } catch {
      setIsSaveError(true);
    } finally {
      ToastsService.open(ToastComponent, {
        type: isSaveError ? "error" : "success",
      });
      setTimeout(() => {
        setIsSaving(false);
        ToastsService.closeToasts();
        setIsSaving(false);
      }, reidrectTimeout);
    }
  };

  const saveDocTemplateAndRecognizeHandler = () => {
    if (templateNameError) {
      return;
    }

    const formData = new FormData();

    const blob = document.imageBlob;
    const file = new File([blob], document.fileName, {
      lastModified: new Date(),
    });

    formData.append("file", file);

    setIsSavingAndRecognize(true);
    dispatch(recognize({formData, templateID: templateUuid})).then((data) => {
      if (!data.error) {
        dispatch(setFileToRecognize(file));
        history.push("/recognition/recognize");
      } else {
        alert("Something is going wrong. Try later.");
      }
      setIsSavingAndRecognize(false);
    });
  };

  const createNewGroupHandler = async (name) => {
    try {
      const responseData = await dispatch(createNewGroup(name));
      await dispatch(getGroups());
      return responseData;
    } catch (err) {
      alert(err);
    }
  };

  const deleteGroupWithConfirmation = (group) => {
    const acceptDeletion = () => {
      dispatch(deleteGroup(group.id))
        .then((data) => {
          if (data.error) {
            throw new Error("Can't delete group");
          }

          if (document.group.id === group.id) {
            dispatch(setTemplateGroup(null));
          }

          dispatch(getGroups()).then((data) => {
            if (data.error) {
              throw new Error("Can't upload groups");
            }
          });
        })
        .catch(() => alert(t("template-editor.error-text")))
        .finally(() => ModalService.close());
    };

    ModalService.open(ConfirmModal, {
      headerText: capitalizeFirstLetter(t("delete-group-confim-modal.header")),
      paragraphText: capitalizeFirstLetter(t("delete-group-confim-modal.paragraph-text")),
      saveButtonText: t("modal.delete").toLocaleUpperCase(),
      onAccept: acceptDeletion,
    });
  };

  const deleteGroupHandler = (e, group) => {
    e.stopPropagation();

    if (group.templates.length) {
      deleteGroupWithConfirmation(group);
      return;
    }

    dispatch(deleteGroup(group.id))
      .then((data) => {
        if (data.error) {
          throw new Error("Can't delete group");
        }

        if (document.group?.id === group.id) {
          dispatch(setTemplateGroup(null));
        }

        dispatch(getGroups()).then((data) => {
          if (data.error) {
            throw new Error("Can't upload groups");
          }
        });
      })
      .catch(() => alert(t("template-editor.error-text")));
  };

  const editGroupHandler = async (groupID, name, isInitName) => {
    if (groupID === document.group?.id) {
      dispatch(setTemplateGroup({id: groupID, name}));
    }
    return await dispatch(editGroup({groupID, name, isInitName}));
  };

  const onWheelHandler = (e) => {
    if (e.deltaY > 0) {
      setScale(scale * 1.1);
    }
    if (e.deltaY < 0) {
      setScale(scale / 1.1);
    }
  };

  const setTemplateGroupHandler = (group) => {
    dispatch(setIsTemplateWereEdit(true));
    dispatch(setTemplateGroup(group));
    dispatch(getGroups());
  };

  // need to drag and drop (upload) new file in canvas and work with it
  useEffect(() => {
    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const createNewDocumentHandler = (file) => {
      const handleFileIsReady = (...fileProperties) => {
        dispatch(
          createNewDocument({
            image: fileProperties[0],
            imageBlob: fileProperties[1],
            fileName: fileProperties[2],
          })
        );
        history.push("/new");
      };

      if (file.type === "application/pdf") {
        processPdfFile(file, handleFileIsReady);
      }
      if (file.type.includes("image")) {
        processImageFile(file, handleFileIsReady);
      }
    };

    const handleDrop = (e) => {
      let files = e.dataTransfer.files;
      createNewDocumentHandler(files[0]);
    };

    const dropArea = window.document.getElementById("canvas-field");

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults);
      window.document.body.addEventListener(eventName, preventDefaults);
    });

    dropArea.addEventListener("drop", handleDrop);

    return function cleanUp() {
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults);
        window.document.body.removeEventListener(eventName, preventDefaults);
      });
      dropArea.removeEventListener("drop", handleDrop);
    };
  }, [history, dispatch]);
  //END OF: drag and drop (upload) new file in canvas and work with it

  useEffect(() => {
    //check and set scale of document
    console.log("changes");
    let scaleByWidth = DEFAULT_SCALE;
    let scaleByHeight = DEFAULT_SCALE;

    if (canvasHeight < document.height) {
      scaleByHeight = (canvasHeight / document.height) * DEFAULT_SCALE;
    }
    if (canvasWidth < document.width) {
      scaleByWidth = (canvasWidth / document.width) * DEFAULT_SCALE;
    }
    const scale = Math.min(scaleByHeight, scaleByWidth);

    setScale(scale);
    setInitialScale(scale);
  }, [canvasHeight, canvasWidth, document.height, document.width]);

  useEffect(() => {
    //check resizing of window object and therefore canvas size
    checkCanvasSize();
    window.addEventListener("resize", checkCanvasSize);

    return function cleanUp() {
      window.removeEventListener("resize", checkCanvasSize);
    };
  }, []);

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  useEffect(() => {
    //show warning when want to reload or leave page
    //only when you are in template redactor
    const isShowWarning =
      location.pathname.includes("/new") || location.pathname.includes("/edit");

    const innerFunc = (event) => {
      if (isShowWarning) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", innerFunc);

    return function cleanUp() {
      window.removeEventListener("beforeunload", innerFunc);
    };
  }, [location.pathname]);

  //for autosaving - only if backlog changed. It means that user made some changes.
  const prevBackLog = usePrevious(backlog);
  const editTemplate = props.onSave;
  useEffect(() => {
    if (prevBackLog && backlog !== prevBackLog) {
      if (document.templateName) {
        //set autosave version by time-----------------------------------------
        setTemplateNameError("");
        setAutosaveTime(
          `version ${String(new Date().getDate()).padStart(2, "0")}.${String(
            new Date().getMonth() + 1
          ).padStart(2, "0")} ${String(new Date().getHours()).padStart(2, "0")}:${String(
            new Date().getMinutes()
          ).padStart(2, "0")}`
        );
        //----------------------------------------------------------------------

        editTemplate({document, areas}, templateUuid).then((data) => {
          if (data.error) {
            if (data.payload === "The template name already exists") {
              setTemplateNameError(t("errors.name-occupied"));
            } else {
              alert(data.payload);
              dispatch(returnOneActionBack());
            }
          }
        });
      } else {
        setTemplateNameError(t("template-editor.empty-value"));
      }
    }
  }, [areas, backlog, dispatch, t, document, editTemplate, prevBackLog, templateUuid]);

  const showHelpModal = () => {
    ModalService.open(HelpModal);
  };

  const cancelAllActions = () => {
    showWarningModal({whereToGoAfterAccept: "/templates"});
  };

  const acceptCancelAllActions = (whereToGoAfterAccept) => {
    const leaveSystem = () => {
      dispatch(logout()).then((data) => {
        if (data.error) {
          alert(data.payload);
        }
      });
    };

    if (whereToGoAfterAccept === "logout") {
      //new template
      if (location.pathname.includes("new")) {
        dispatch(deleteTemplate(templateUuid)).then((data) => {
          if (!data.error) {
            ModalService.close();
            leaveSystem();
          } else {
            alert("Something is going wrong. Try later.");
          }
        });
        return;
      }
      //edit template
      editTemplate(
        {document: initialData.document, areas: initialData.areas},
        templateUuid
      ).then((data) => {
        if (!data.error) {
          ModalService.close();
          leaveSystem();
        } else {
          alert("Something is going wrong. Try later.");
        }
      });
      //unnecessary 'return' statement
      return;
    }
    //change the page or cancell all changes by button
    else {
      if (location.pathname.includes("new")) {
        //new template
        dispatch(deleteTemplate(templateUuid)).then((data) => {
          if (!data.error) {
            ModalService.close();
            history.push(whereToGoAfterAccept);
          } else {
            alert("Something is going wrong. Try later.");
          }
        });
        return;
      }
      //edit template
      editTemplate(
        {document: initialData.document, areas: initialData.areas, meta: "cancel"},
        templateUuid
      ).then((data) => {
        if (!data.error) {
          ModalService.close();
          history.push(whereToGoAfterAccept);
        } else {
          alert("Something is going wrong. Try later.");
        }
      });
    }
  };

  const showWarningModal = ({whereToGoAfterAccept}) => {
    ModalService.open(WarningModal, {
      headerText: capitalizeFirstLetter(t("template-editor.warning-modal-title")),
      bodyText: capitalizeFirstLetter(t("template-editor.warning-modal-text")),
      leftButtonText: t("template-editor.warning-left-button-text").toLocaleUpperCase(),
      rightButtonText: t("template-editor.warning-right-button-text").toLocaleUpperCase(),
      onClickAccept: () => acceptCancelAllActions(whereToGoAfterAccept),
    });
  };
  //--------------------------------------------------------------------------------
  useEffect(() => {
    dispatch(setIsTemplateWereEdit(false));
  }, [dispatch]);

  useEffect(() => {
    switch (wizardStep) {
      case 0:
        if (wizardFirstTargetNode) {
          setWizardTarget(wizardFirstTargetNode);
          setWizardRadius("125px");
          setWizardHelperText([
            t("wizard.template-editor-text1-part1"),
            t("wizard.template-editor-text1-part2"),
            t("wizard.template-editor-text1-part3"),
            t("wizard.template-editor-text1-part4"),
            t("wizard.template-editor-text1-part5"),
            t("wizard.template-editor-text1-part6"),
          ]);
          setWizardXHelperText(50);
          setWizardYHelperText(10);
          setWizardNextStepFunction({nextStep: () => setWizardStep(1)});
        }
        break;
      case 1:
        if (canvasWrapperRef.current) {
          setWizardTarget(canvasWrapperRef?.current);
          setWizardRadius("50%");
          setWizardPointer(null);
          setWizardHelperText([t("wizard.template-editor-text2")]);
          setWizardXHelperText(5);
          setWizardYHelperText(47.5);
          setWizardNextStepFunction({nextStep: () => setWizardStep(3)});
        }
        break;
      case 2:
        if (wizardThirdTargetNode) {
          setWizardTarget(wizardThirdTargetNode);
          setWizardRadius("300px");
          setWizardPointer(wizardThirdPointerNode);
          setWizardHelperText([t("wizard.template-editor-text3")]);
          setWizardNextStepFunction(null);
          setWizardXHelperText(60);
          setWizardYHelperText(75);
        }
        break;
      case 3:
        if (wizardFourthTargetNode) {
          setWizardTarget(wizardFourthTargetNode);
          setWizardRadius("55%");
          setWizardPointer(null);
          setWizardHelperText([t("wizard.template-editor-text4")]);
          setWizardNextStepFunction({nextStep: () => setWizardStep(4)});
          setWizardYHelperText(35);
          setWizardXHelperText(20);
        }
        break;
      case 4:
        if (wizardFifthTargetNode) {
          setWizardTarget(wizardFifthTargetNode);
          setWizardRadius("220px");
          setWizardHelperText([t("wizard.template-editor-text5")]);
          setWizardYHelperText(55);
          setWizardXHelperText(20);
          setWizardNextStepFunction(null);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wizardStep,
    wizardFirstTargetNode,
    canvasWrapperRef.current,
    wizardThirdTargetNode,
    wizardFourthTargetNode,
    wizardFifthTargetNode,
    wizardThirdPointerNode,
  ]);

  let cursor = "default";
  switch (currTool) {
    case ControlPanelTools.SELECTING:
      cursor = "crosshair";
      break;
    case ControlPanelTools.MOVING:
      cursor = "all-scroll";
      break;
    default:
      cursor = "default";
      break;
  }

  return (
    <WizardLayout
      targetNode={wizardTarget}
      pointerNode={wizardPointer}
      radius={wizardRadius}
      helperTextList={wizardHelperText}
      xHelperText={wizardXHelperText}
      yHelperText={wizardYHelperText}
      onNextStep={wizardNextStepFunction?.nextStep}
    >
      <TemplateEditorHeader onShowWarningModal={showWarningModal} />
      <Row noGutters id="template-editor" style={props.style}>
        <Col
          id="edit-box"
          className="position-relative"
          style={{
            backgroundColor: MainColors.greyPalette.grey1,
          }}
        >
          <EditBox
            ref={{wizardFourthTargetRef, wizardFifthTargetRef}}
            isSaving={isSaving}
            isSavingAndRecognize={isSavingAndRecognize}
            autosaveTime={autosaveTime}
            areas={areas}
            highlightedAreas={highlightedAreas}
            document={document}
            groups={groups}
            templateNameError={templateNameError}
            onCreateNewGroup={createNewGroupHandler}
            onGetGroups={() => dispatch(getGroups())}
            onDeleteGroup={deleteGroupHandler}
            onEditGroup={editGroupHandler}
            onHighlightArea={hightLightAreaHandler}
            onDocumentNameChanged={setDocumentNameHandler}
            onEditArea={editBoxAreaOnClickHandler("edit")}
            onRemoveArea={editBoxAreaOnClickHandler("remove")}
            onSaveDocTemplate={saveDocTemplateHandler}
            onSaveDocTemplateAndRecognize={saveDocTemplateAndRecognizeHandler}
            onCancelSavingDocTemplate={cancelAllActions}
            onSetTemplateGroup={setTemplateGroupHandler}
            onWizardNextStep={() => setWizardStep(9)}
          />
        </Col>
        <Col
          id="canvas-with-control-panel-field"
          className="p-0 d-flex position-relative"
          onWheel={onWheelHandler}
        >
          <SizeTumbler
            scale={scale}
            onChangeScale={changeScaleHandler}
            initialScale={initialScale}
          />
          {/************************Control Panel Field***************************************/}
          <div
            ref={wizardFirstTargetRef}
            id="control-panel-field"
            className="template-editor-control-panel"
          >
            <ControlPanel onChangeMode={() => setSelectedAreaId(null)} />
          </div>
          <ActionsManagePanel
            className="template-editor-manage-actions-panel"
            canceledActions={canceledActions}
            backlog={backlog}
            onReturnOneActionBack={() => dispatch(returnOneActionBack())}
            onReturnOneActionForward={() => dispatch(returnOneActionForward())}
          />
          <Help onClick={() => showHelpModal()} />
          {/************************End Of Control Panel Field********************************/}
          {/********************************Canvas Field**************************************/}
          <div
            id="canvas-field"
            ref={canvasWrapperRef}
            style={{width: "100%", overflow: "hidden"}}
            className="template-editor-document-field border background-grey"
          >
            <Stage
              ref={stageRef}
              draggable={currTool === ControlPanelTools.MOVING}
              onMouseDown={
                currTool === ControlPanelTools.MOVING && checkDeselectAreaHandler
              }
              width={canvasWidth}
              height={canvasHeight}
              scaleX={scale}
              scaleY={scale}
              style={{cursor}}
            >
              {document &&
              (document.image.nodeName === "IMG" ||
                document.image.toString() ===
                  "[object ImageBitmap]") /*this is kostyl. Remake it*/ && (
                  <Layer>
                    <Image
                      ref={templateImageRef}
                      y={imageYOffset / scale}
                      x={imageXOffset / scale}
                      image={document.image}
                    />
                  </Layer>
                )}
              <AreasList
                stage={stageRef.current}
                areas={areas.map((area) => convertAreaCoordinates("relative", area))}
                highlightedAreas={highlightedAreas}
                scale={scale}
                cursor={cursor}
                currTool={currTool}
                selectedAreaId={selectedAreaId}
                onEditArea={editAreaOnClickHandler}
                onSelectArea={selectAreaHandler}
                onDragArea={dragAreaHandler}
                onTransformArea={transfromAreaHanlder}
                onHighlightArea={hightLightAreaHandler}
                onDeleteArea={deleteByCloseIconHandler}
              />
              {currTool === ControlPanelTools.SELECTING && (
                <Selector
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                  stageRef={stageRef}
                  templateImageRef={templateImageRef}
                  imageXOffset={imageXOffset}
                  imageYOffset={imageYOffset}
                  onDraw={drawAreaBySelectorHandler}
                />
              )}
            </Stage>
            {showCreateAreaForm?.status && (
              <div
                ref={wizardThirdTargetRef}
                className="d-flex template-editor-create-area-form"
              >
                <SaveAreaForm
                  ref={wizardThirdPointerRef}
                  showCreateAreaForm={showCreateAreaForm}
                  className="m-auto"
                  hiddenCancel={!localStorage.getItem("parsio.skipWizardStatus")}
                  onCancel={cancelSavingAreaHandler}
                  onSave={saveAreaHandler}
                  area={area}
                  areas={areas}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/******************************End Of Canvas Field************************************/}
    </WizardLayout>
  );
};

const TemplateEditorForwardingRef = React.forwardRef((props, ref) => (
  <TemplateEditor {...props} forwardedRef={ref} />
));

export default connect(null, null, null, {forwardRef: true})(TemplateEditorForwardingRef);
