const ModalService = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  open(component, props = {}) {
    document.dispatchEvent(new CustomEvent("open-modal", {detail: {component, props}}));
  },
  close() {
    document.dispatchEvent(new CustomEvent("close-modal"));
  },
};

export default ModalService;
