import React, {useState, useEffect} from "react";
import ToastsService from "../services/ToastsService";
import "./Toasts.scss";

export default function ModalRoot() {
  const [toast, setToast] = useState({});

  useEffect(() => {
    ToastsService.on("open-toast", ({component, props}) => {
      setToast({
        component,
        props,
        close: () => {
          setToast({});
        },
      });
    });
    ToastsService.on("close-toast", () => {
      setToast({});
    });
  }, []);

  const ModalComponent = toast.component ? toast.component : null;

  return (
    ModalComponent && (
      <ModalComponent
        {...toast.props}
        className={ModalComponent ? "d-block fixed-toast" : ""}
      />
    )
  );
}
